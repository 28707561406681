import { Card, Grid, Button } from "@mui/material";
import { useReducer, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useTeam } from "../../hooks/useTeam";
import { api } from "../../util/api";
import { useDesigns } from "../../hooks/useDesigns";
import Picker from "./Picker";
import MaterialList from "./Materials";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImageIcon from "@mui/icons-material/Image";
import { v4 } from "uuid";
import { mutate } from "swr";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const defaultDesign = {
  materials: [],
  lengthOfSection: 8,
  name: "",
  image: "",
};

// const defaultDesign = {
//   materials: shadowBox.materials,
//   lengthOfSection: shadowBox.lengthOfSection,
//   name: shadowBox.name,
// };

function reducer(state, action) {
  if (action.type === "updateImage") {
    return {
      ...state,
      image: action.payload,
    };
  }

  if (action.type === "updateDesignName") {
    return {
      ...state,
      name: action.payload,
    };
  }

  if (action.type === "updateLengthOfSection") {
    return {
      ...state,
      lengthOfSection: action.payload,
    };
  }

  if (action.type === "addMaterial") {
    return {
      ...state,
      materials: [...state.materials, action.payload],
    };
  }

  if (action.type === "updateMaterial") {
    return {
      ...state,
      materials: state.materials.map((material) => {
        if (material.id === action.payload.id) {
          return action.payload;
        }
        return material;
      }),
    };
  }

  if (action.type === "removeMaterial") {
    return {
      ...state,
      materials: state.materials.filter(
        (material) => material.id !== action.payload.id
      ),
    };
  }

  if (action.type === "copyDesign") {
    return { ...defaultDesign, ...action.payload, id: v4() };
  }

  if (action.type === "resetDesign") {
    return defaultDesign;
  }

  throw Error("Unknown action.");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const Design = () => {
  const [tab, setTab] = useState(0);

  const [designPageState, dispatch] = useReducer(reducer, defaultDesign);
  const { isLoading, error, designs, mutate } = useDesigns();

  const { isLoading: teamIsLoading, error: teamError } = useTeam();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <Card>
      <div>
        <h1>Design</h1>

        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Designs" />
          <Tab label="Build" />
          <Tab label="All Materials" />
        </Tabs>

        <TabPanel value={tab} index={0}>
          <Designs designPageState={designPageState} dispatch={dispatch} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {designs.length < 3 && (
            <Picker designPageState={designPageState} dispatch={dispatch} />
          )}
          <MaterialList designPageState={designPageState} dispatch={dispatch} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <AllMaterials
            designPageState={designPageState}
            dispatch={dispatch}
            designs={designs}
            mutate={mutate}
          />
        </TabPanel>
      </div>
    </Card>
  );
};

const AllMaterials = ({ designs }) => {
  const getUniqueMaterials = () => {
    const materials = designs.reduce((acc, design) => {
      design.materials.forEach((material) => {
        if (!Object.keys(acc).includes(material.material)) {
          acc[material.material] = { [material.price]: [design.name] };
        }

        if (Object.keys(acc).includes(material.material)) {
          acc[material.material][material.price] = [
            ...(acc[material.material][material.price] || []),
            design.name,
          ];
        }
      });
      return acc;
    }, {});
    return materials;
  };

  const mats = getUniqueMaterials();
  console.log(mats);

  return (
    <Card>
      <div>
        <h1>Bulk Edit</h1>
        <Grid container spacing={2}>
          {Object.keys(mats)
            .sort((a, b) => a.localeCompare(b))
            .map((material, idx) => {
              // if (Object.values(mats[material]).length === 1) {
              //   return null;
              // }

              return (
                <Grid item xs={12} md={12} key={`${v4()}`}>
                  <h3>{material}</h3>
                  {Object.entries(mats[material]).map((k) => {
                    const designFound = k[1].map((id) => {
                      // console.log(id);
                      return designs.find((design) => design.name === id);
                    });

                    return (
                      <div key={`${v4()}`}>
                        <EditDesignBulk
                          designFound={designFound}
                          price={k[0]}
                          material={material}
                        />
                      </div>
                    );
                  })}
                </Grid>
              );
            })}
        </Grid>
      </div>
    </Card>
  );
};

const EditDesignBulk = ({ designFound, material, price }) => {
  const [ready, setReady] = useState(false);
  const [editNameReady, setEditNameReady] = useState(false);

  const editPrice = async (values) => {
    designFound
      .forEach(async (design) => {
        const newMaterials = design.materials.map((mat) => {
          // console.log(mat.material, material);
          if (mat.material === material) {
            return { ...mat, price: values.price };
          }
          return mat;
        });

        const newDesign = { materials: newMaterials };

        await api("PUT", `/team/design/${design.id}`, newDesign);
      })
      .then(() => {
        mutate();
        setReady(false);
      });
  };

  const editName = async (values) => {
    designFound.forEach(async (design) => {
      const newMaterials = design.materials.map((mat) => {
        if (mat.material === material) {
          return { ...mat, material: values.material };
        }
        return mat;
      });

      const newDesign = { materials: newMaterials };

      await api("PUT", `/team/design/${design.id}`, newDesign);
    });
    mutate();
    setEditNameReady(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setEditNameReady(!ready)}>
        Change Name
      </Button>
      <br />
      <Button variant="contained" onClick={() => setReady(!ready)}>
        {price}
      </Button>

      {editNameReady && (
        <Formik
          initialValues={{
            material,
          }}
          validationSchema={Yup.object().shape({
            material: Yup.string().required("Required"),
          })}
          onSubmit={editName}
        >
          {({ submitForm, isSubmitting }) => (
            <div>
              <Form>
                <Grid item xs={12} md={3}>
                  <Field component={TextField} name="material" label="Name" />
                </Grid>
                <Grid item xs={12} md={3}>
                  {isSubmitting ? (
                    <div className="spinner" />
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Bulk Edit
                    </Button>
                  )}
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      )}
      {ready && (
        <Formik
          initialValues={{
            price,
          }}
          validationSchema={Yup.object().shape({
            price: Yup.number().required("Required"),
          })}
          onSubmit={editPrice}
        >
          {({ submitForm, isSubmitting }) => (
            <div>
              <Form>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    name="price"
                    type="number"
                    label="Price"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  {isSubmitting ? (
                    <div className="spinner" />
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Bulk Edit
                    </Button>
                  )}
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      )}
      <p>{designFound.map((d) => d.name).join(", ")}</p>
    </div>
  );
};

const DesignRow = ({ design, dispatch, designPageState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, designs } = useDesigns();
  const [areYouSureImage, setAreYouSureImage] = useState(false);
  const [areYouSureDesign, setAreYouSureDesign] = useState(false);

  // if (error) {
  //   return <p>Error! please refresh the page, if the problem persists contact fencewizapp@gmail.com</p>;
  // }
  const saveButton = async (values, actions) => {
    enqueueSnackbar("Saving Design Name");
    await api("PUT", `/team/design/${design.id}`, values);
    mutate(designs.map((d) => (d.id === design.id ? { ...d, ...values } : d)));
    enqueueSnackbar("Design Name Updated", { variant: "success" });
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = async () => {
        // setOriginalImage(event.target.result);
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        }).then(async (resizedBitmap) => {
          let quality = 0.99;
          let canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length > 1000000) {
            quality -= 0.01;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          // console.log(dataUrl);
          enqueueSnackbar("Saving Design Image");

          api("PUT", `/team/design/${design.id}`, { image: dataUrl });
          mutate(
            designs.map((d) =>
              d.id === design.id ? { ...d, image: dataUrl } : d
            ),
            { revalidate: false }
          );
          enqueueSnackbar("Design Image Updated", { variant: "success" });
          // setResizedImage(dataUrl);
        });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const areYouSureButtonDeleteDesign = async () => {
    try {
      enqueueSnackbar("Deleting Design");

      await api("DELETE", `/team/design/${design.id}`);
      mutate(designs.filter((d) => d.id !== design.id));
      enqueueSnackbar("Deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.response.data, { variant: "error" });
    }
    setAreYouSureImage(false)

  };

  const areYouSureButtonDeleteImage = async (values, actions) => {
    try {
      enqueueSnackbar("Deleting Image");
      const newDesigns = designs.map((d) =>
        d.id === design.id ? { ...d, image: null } : d
      );

      await api("PUT", `/team/design/${design.id}`, { image: null });
      mutate(newDesigns);
      enqueueSnackbar("Deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.response.data, { variant: "error" });
    }
    setAreYouSureImage(false)
  };

  const deleteImageButton = () => setAreYouSureImage(true);

  const deleteDesignButton = () => setAreYouSureDesign(true)


  const copyToTable = () => {
    dispatch({ type: "resetDesign" });

    dispatch({ type: "copyDesign", payload: design });
    enqueueSnackbar("Copied", { variant: "success" });

    // setMaterials(design.materials);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: design.name,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        })}
        onSubmit={saveButton}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Field component={TextField} name="name" label="Design Name" />
              </Grid>

              {design.image ? (
                <Grid item xs={12} md={12}>
                  <div>
                    <img
                      alt="design"
                      src={design.image}
                      style={{
                        width: "400px",
                        maxWidth: "100%",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </Grid>
              ) : null}

              {isSubmitting ? (
                <div className="spinner" />
              ) : (
                <Grid item xs={3}>
                  <div>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <SaveIcon />
                    </Button>
                  </div>
                </Grid>
              )}

              <Grid item xs={3}>
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={copyToTable}
                  >
                    <ContentCopyIcon />
                  </Button>
                </div>
              </Grid>

              <Grid item xs={3}>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id={design.id}
                    onChange={handleImageChange}
                  />
                  <label htmlFor={design.id}>
                    <Button component="span" variant="contained" fullWidth id={v4()}
                    >
                      <ImageIcon />
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div>

                  {
                    areYouSureImage === false ? (
                      <Button
                        component="span"
                        variant="contained"
                        color="error"
                        fullWidth
                        id={v4()}
                        onClick={deleteImageButton}
                      >
                        <ImageIcon />
                      </Button>
                    ) : null}
                  {areYouSureImage === true ? (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={areYouSureButtonDeleteImage}
                      >
                        Are you sure?
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setAreYouSureImage(false)}
                      >
                        No
                      </Button>
                    </>
                  ) : null}

                </div>

                <div>

                  {
                    areYouSureDesign === false ? (
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        disabled={isSubmitting}
                        onClick={deleteDesignButton}
                      >
                        <DeleteIcon />
                      </Button>
                    ) : null}
                  {areYouSureDesign ? (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={areYouSureButtonDeleteDesign}
                      >
                        Are you sure?
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setAreYouSureDesign(false)}
                      >
                        No
                      </Button>
                    </>
                  ) : null}

                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Designs = ({ dispatch, designPageState }) => {
  const { designs, isLoading, error } = useDesigns();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading || !designs) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <Card>
      <div>
        <h2>{team.team.name} Designs</h2>
        {team.tierLimits.designs <= designs.length && (
          <p>No More Designs Allowed</p>
        )}
        {designs?.length === 0 && <p>No designs yet</p>}

        {designs
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((design) => (
            <DesignRow
              key={design.id}
              design={design}
              designPageState={designPageState}
              dispatch={dispatch}
            />
          ))}
      </div>
    </Card>
  );
};

export default Design;
