import { API, Auth } from "aws-amplify";

export const fetcher = async (resource, init) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  return API.get("backend", resource, {
    headers: {
      authorization: `${token}`,
    },
  });
};
