import * as React from "react";
import Button from "@mui/material/Button";
import { Card, FormControlLabel, Switch, TextField } from "@mui/material";
import sevenohfive from "./705.jpg";
import DrawingArea from "./DrawingArea";

const weights = {
  "PT 4x4x8": 30.8,
  "PT 4x4x10": 38.5,
  "PT 5x5x10": 62.5,
  "Cedar 4x4x10": 23.08,
  "Cedar 4x4x12": 30,
  "PT 6x6x10": 90,
  "Cedar 6x6x10": 50,
  "PT 2x4x8": 11.39,
  "PT Grooved 2x4x8": 11.39,
  "PT 2x6x8": 16,
  "Cedar 2x4x8": 9.13,
  "Cedar 2x6x8": 12.96,
  "PT 1x6x5": 5,
  "PT 1x6x6": 6.78,
  "PT 1x6x8": 9.13,
  "PT 1x4x8": 5,
  "PT 5/4x6x8": 12,
  "Cedar 1x6x5": 3.79,
  "Cedar 1x6x6": 5,
  "Cedar 1x6x8": 7,
  "Cedar 5/4x6x8": 8.52,
  "30KG Concrete": 66,
  "30KG Rapid Concrete": 66,
  "Lattice 1x8": 8.25,
};

const posts = {
  "PT 4x4x8": { price: 17.62, quantity: 0 },
  "PT 4x4x10": { price: 22.03, quantity: 1 },
  "PT 5x5x10": { price: 38.51, quantity: 0 },
  "Cedar 4x4x10": { price: 45.53, quantity: 0 },
  "PT 6x6x10": { price: 55.46, quantity: 0 },
  "PT 6x6x12": { price: 66.55, quantity: 0 },
  "Cedar 4x4x12": { price: 68.3, quantity: 0 },
  "Cedar 6x6x10": { price: 223.4, quantity: 0 },
  "Post cap": { price: 4, quantity: 1 },
};

const beams = {
  "PT 2x4x8": { price: 9.23, quantity: 4 },
  "PT Grooved 2x4x8": { price: 13.85, quantity: 0 },
  "PT 2x6x8": { price: 16.15, quantity: 0 },
  "Cedar 2x4x8": { price: 16.34, quantity: 0 },
  "Cedar 2x6x8": { price: 27.98, quantity: 0 },
};

const boards = {
  "PT 1x6x5": { price: 4.66, quantity: 0 },
  "PT 1x4x8": { price: 6.25, quantity: 0 },
  "PT 1x6x6": { price: 5.59, quantity: 17 },
  "PT 1x6x8": { price: 7.45, quantity: 0 },
  "PT 5/4x6x8": { price: 9.2, quantity: 0 },
  "Cedar 1x6x5": { price: 8.21, quantity: 0 },
  "Cedar 1x6x6": { price: 10.79, quantity: 0 },
  "Cedar 1x6x8": { price: 13.64, quantity: 0 },
  "Cedar 5/4x6x8": { price: 17.08, quantity: 0 },
};

const concrete = {
  "30KG Concrete": { price: 6.43, quantity: 0 },
  "30KG Rapid Concrete": { price: 8.77, quantity: 2 },
};

const other = {
  "Lattice 1x8": { price: 11.98, quantity: 0 },
  "PT 2x2x8": { price: 6.87, quantity: 0 },
  "2x4 Brackets": { price: 0.49, quantity: 6 },
};

const semiPrivate = () => {
  const newBoards = {
    "PT 1x6x5": { price: boards["PT 1x6x5"].price, quantity: 0 },
    "PT 1x4x8": { price: boards["PT 1x4x8"].price, quantity: 0 },
    "PT 1x6x6": { price: boards["PT 1x6x6"].price, quantity: 22 },
    "PT 1x6x8": { price: boards["PT 1x6x8"].price, quantity: 0 },
    "PT 5/4x6x8": { price: boards["PT 5/4x6x8"].price, quantity: 0 },
    "Cedar 1x6x5": { price: boards["Cedar 1x6x5"].price, quantity: 0 },
    "Cedar 1x6x6": { price: boards["Cedar 1x6x6"].price, quantity: 0 },
    "Cedar 1x6x8": { price: boards["Cedar 1x6x8"].price, quantity: 0 },
    "Cedar 5/4x6x8": { price: boards["Cedar 5/4x6x8"].price, quantity: 0 },
  };

  return {
    posts,
    beams,
    boards: newBoards,
    concrete,
    other,
  };
};

const sixbysixcheap = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 0 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 1 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBeams = {
    "PT 2x4x8": { price: beams["PT 2x4x8"].price, quantity: 3 },
    "PT Grooved 2x4x8": { price: beams["PT Grooved 2x4x8"].price, quantity: 0 },
    "PT 2x6x8": { price: beams["PT 2x6x8"].price, quantity: 1 },
    "Cedar 2x4x8": { price: beams["Cedar 2x4x8"].price, quantity: 0 },
    "Cedar 2x6x8": { price: beams["Cedar 2x6x8"].price, quantity: 0 },
  };

  return {
    posts: newPosts,
    beams: newBeams,
    boards,
    concrete,
    other,
  };
};

const sixbysix = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 0 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 1 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBeams = {
    "PT 2x4x8": { price: beams["PT 2x4x8"].price, quantity: 0 },
    "PT Grooved 2x4x8": { price: beams["PT Grooved 2x4x8"].price, quantity: 0 },
    "PT 2x6x8": { price: beams["PT 2x6x8"].price, quantity: 4 },
    "Cedar 2x4x8": { price: beams["Cedar 2x4x8"].price, quantity: 0 },
    "Cedar 2x6x8": { price: beams["Cedar 2x6x8"].price, quantity: 0 },
  };

  return {
    posts: newPosts,
    beams: newBeams,
    boards,
    concrete,
    other,
  };
};

const fivebyfive = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 1 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 0 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  return {
    posts: newPosts,
    beams,
    boards,
    concrete,
    other,
  };
};

const fivebyfiveSemi = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 1 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 0 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBoards = {
    "PT 1x6x5": { price: boards["PT 1x6x5"].price, quantity: 0 },
    "PT 1x4x8": { price: boards["PT 1x4x8"].price, quantity: 0 },
    "PT 1x6x6": { price: boards["PT 1x6x6"].price, quantity: 22 },
    "PT 1x6x8": { price: boards["PT 1x6x8"].price, quantity: 0 },
    "PT 5/4x6x8": { price: boards["PT 5/4x6x8"].price, quantity: 0 },
    "Cedar 1x6x5": { price: boards["Cedar 1x6x5"].price, quantity: 0 },
    "Cedar 1x6x6": { price: boards["Cedar 1x6x6"].price, quantity: 0 },
    "Cedar 1x6x8": { price: boards["Cedar 1x6x8"].price, quantity: 0 },
    "Cedar 5/4x6x8": { price: boards["Cedar 5/4x6x8"].price, quantity: 0 },
  };
  return {
    posts: newPosts,
    beams,
    boards: newBoards,
    concrete,
    other,
  };
};

const sixbysixsemi = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 0 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 1 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBeams = {
    "PT 2x4x8": { price: beams["PT 2x4x8"].price, quantity: 0 },
    "PT Grooved 2x4x8": { price: beams["PT Grooved 2x4x8"].price, quantity: 0 },
    "PT 2x6x8": { price: beams["PT 2x6x8"].price, quantity: 4 },
    "Cedar 2x4x8": { price: beams["Cedar 2x4x8"].price, quantity: 0 },
    "Cedar 2x6x8": { price: beams["Cedar 2x6x8"].price, quantity: 0 },
  };
  const newBoards = {
    "PT 1x6x5": { price: boards["PT 1x6x5"].price, quantity: 0 },
    "PT 1x4x8": { price: boards["PT 1x4x8"].price, quantity: 0 },
    "PT 1x6x6": { price: boards["PT 1x6x6"].price, quantity: 22 },
    "PT 1x6x8": { price: boards["PT 1x6x8"].price, quantity: 0 },
    "PT 5/4x6x8": { price: boards["PT 5/4x6x8"].price, quantity: 0 },
    "Cedar 1x6x5": { price: boards["Cedar 1x6x5"].price, quantity: 0 },
    "Cedar 1x6x6": { price: boards["Cedar 1x6x6"].price, quantity: 0 },
    "Cedar 1x6x8": { price: boards["Cedar 1x6x8"].price, quantity: 0 },
    "Cedar 5/4x6x8": { price: boards["Cedar 5/4x6x8"].price, quantity: 0 },
  };

  return {
    posts: newPosts,
    beams: newBeams,
    boards: newBoards,
    concrete,
    other,
  };
};

const cedarfourbyfour = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 0 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 1 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 0 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 0 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBeams = {
    "PT 2x4x8": { price: beams["PT 2x4x8"].price, quantity: 0 },
    "PT Grooved 2x4x8": { price: beams["PT Grooved 2x4x8"].price, quantity: 0 },
    "PT 2x6x8": { price: beams["PT 2x6x8"].price, quantity: 0 },
    "Cedar 2x4x8": { price: beams["Cedar 2x4x8"].price, quantity: 4 },
    "Cedar 2x6x8": { price: beams["Cedar 2x6x8"].price, quantity: 0 },
  };
  const newBoards = {
    "PT 1x6x5": { price: boards["PT 1x6x5"].price, quantity: 0 },
    "PT 1x4x8": { price: boards["PT 1x4x8"].price, quantity: 0 },
    "PT 1x6x6": { price: boards["PT 1x6x6"].price, quantity: 0 },
    "PT 1x6x8": { price: boards["PT 1x6x8"].price, quantity: 0 },
    "PT 5/4x6x8": { price: boards["PT 5/4x6x8"].price, quantity: 0 },
    "Cedar 1x6x5": { price: boards["Cedar 1x6x5"].price, quantity: 0 },
    "Cedar 1x6x6": { price: boards["Cedar 1x6x6"].price, quantity: 17 },
    "Cedar 1x6x8": { price: boards["Cedar 1x6x8"].price, quantity: 0 },
    "Cedar 5/4x6x8": { price: boards["Cedar 5/4x6x8"].price, quantity: 0 },
  };

  return {
    posts: newPosts,
    beams: newBeams,
    boards: newBoards,
    concrete,
    other,
  };
};

const cedarsixbysix = () => {
  const newPosts = {
    "PT 4x4x8": { price: posts["PT 4x4x8"].price, quantity: 0 },
    "PT 4x4x10": { price: posts["PT 4x4x10"].price, quantity: 0 },
    "PT 5x5x10": { price: posts["PT 5x5x10"].price, quantity: 0 },
    "Cedar 4x4x10": { price: posts["Cedar 4x4x10"].price, quantity: 0 },
    "PT 6x6x10": { price: posts["PT 6x6x10"].price, quantity: 0 },
    "PT 6x6x12": { price: posts["PT 6x6x12"].price, quantity: 0 },
    "Cedar 4x4x12": { price: posts["Cedar 4x4x12"].price, quantity: 0 },
    "Cedar 6x6x10": { price: posts["Cedar 6x6x10"].price, quantity: 1 },
    "Post cap": { price: posts["Post cap"].price, quantity: 1 },
  };

  const newBeams = {
    "PT 2x4x8": { price: beams["PT 2x4x8"].price, quantity: 0 },
    "PT Grooved 2x4x8": { price: beams["PT Grooved 2x4x8"].price, quantity: 0 },
    "PT 2x6x8": { price: beams["PT 2x6x8"].price, quantity: 0 },
    "Cedar 2x4x8": { price: beams["Cedar 2x4x8"].price, quantity: 0 },
    "Cedar 2x6x8": { price: beams["Cedar 2x6x8"].price, quantity: 4 },
  };
  const newBoards = {
    "PT 1x6x5": { price: boards["PT 1x6x5"].price, quantity: 0 },
    "PT 1x4x8": { price: boards["PT 1x4x8"].price, quantity: 0 },
    "PT 1x6x6": { price: boards["PT 1x6x6"].price, quantity: 0 },
    "PT 1x6x8": { price: boards["PT 1x6x8"].price, quantity: 0 },
    "PT 5/4x6x8": { price: boards["PT 5/4x6x8"].price, quantity: 0 },
    "Cedar 1x6x5": { price: boards["Cedar 1x6x5"].price, quantity: 0 },
    "Cedar 1x6x6": { price: boards["Cedar 1x6x6"].price, quantity: 17 },
    "Cedar 1x6x8": { price: boards["Cedar 1x6x8"].price, quantity: 0 },
    "Cedar 5/4x6x8": { price: boards["Cedar 5/4x6x8"].price, quantity: 0 },
  };

  return {
    posts: newPosts,
    beams: newBeams,
    boards: newBoards,
    concrete,
    other,
  };
};

const fenceTypes = {
  "4x4 Simple Private": {
    posts,
    beams,
    boards,
    concrete,
    other,
  },
  "4x4 Semi Private": semiPrivate(),
  "5x5": fivebyfive(),
  "5x5 Semi": fivebyfiveSemi(),
  "6x6 Cheap": sixbysixcheap(),
  "6x6 Semi": sixbysixsemi(),
  "6x6": sixbysix(),
  "Cedar 4x4": cedarfourbyfour(),
  "Cedar 6x6": cedarsixbysix(),
};

const materialsCategory = ["posts", "concrete", "beams", "boards", "other"];
const postCategory = ["posts", "concrete"];
// const panelCategory = ["beams", "boards", "other"];

const getPrices = (materialList) => {
  let materials = {};

  for (let mat of materialsCategory) {
    materials[mat] = Object.values(materialList[mat]).reduce(
      (acc, p) => parseFloat(p.price) * parseFloat(p.quantity) + acc,
      0
    );
  }

  return materials;
};

const getWeights = (materialList, posts) => {
  let totalWeight = 0;

  for (let mat of materialsCategory) {
    totalWeight += Object.keys(materialList[mat]).reduce((acc, curr) => {
      // console.log(curr);
      // console.log(weights[curr]);

      if (!weights[curr]) {
        return acc;
      }

      let newAcc = weights[curr] * materialList[mat][curr].quantity + acc;
      // console.log(curr, weights[curr], materialList[mat][curr].quantity);

      return newAcc;
    }, 0);
  }

  return totalWeight * posts;
};

const MatCard = ({
  m,
  materials,
  setMaterials,
  totalPosts,
  totalSections,
  slim,
}) => {
  const [clean, setClean] = React.useState(true);
  return (
    <Card>
      <div
        key={m}
        style={{
          // border: "solid",
          color:
            Object.values(materials[m]).reduce(
              (acc, curr) => acc + curr.quantity,
              0
            ) > 0
              ? "green"
              : "red",
        }}
      >
        <h1 style={{ textTransform: "capitalize" }}>{m}</h1>

        <Switch checked={clean} onChange={(e) => setClean(!clean)} />

        {Object.entries(materials[m]).map((p) => {
          if (clean && p[1].quantity === 0) {
            return null;
          }

          return (
            <Card
              key={p[0]}
              style={
                p[1].quantity && !clean
                  ? { background: "rgba(0, 128, 0, 0.2)" }
                  : undefined
              }
            >
              <div style={{ padding: "1em" }}>
                <div>
                  <TextField value={p[0]} label="Material" />
                  {!slim ? (
                    <TextField value={p[1].price} type="number" label="Price" />
                  ) : null}

                  <TextField
                    type="number"
                    value={p[1].quantity}
                    label="Quantity"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setMaterials({
                          ...materials,
                          [m]: {
                            ...materials[m],
                            [p[0]]: {
                              ...materials[m][p[0]],
                              quantity: "",
                            },
                          },
                        });
                      } else {
                        let event = parseFloat(e.target.value);
                        if (event || event === 0) {
                          setMaterials({
                            ...materials,
                            [m]: {
                              ...materials[m],
                              [p[0]]: {
                                ...materials[m][p[0]],
                                quantity: parseFloat(e.target.value),
                              },
                            },
                          });
                        }
                      }
                    }}
                  />

                  {!slim ? (
                    <>
                      <TextField
                        label="Total Quantity"
                        value={
                          postCategory.includes(m)
                            ? p[1].quantity * totalPosts
                            : p[1].quantity * totalSections
                        }
                      />

                      <TextField
                        label="Total Cost"
                        value={
                          postCategory.includes(m)
                            ? p[1].quantity * totalPosts * p[1].price
                            : p[1].quantity * totalSections * p[1].price
                        }
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </Card>
  );
};

export default function MyApp() {
  const [sides, setSides] = React.useState({ 1: 50 });
  const [redact, setRedact] = React.useState(true);
  const [sharedPosts, setSharedPosts] = React.useState(0);
  const [panelSize, setPanelSize] = React.useState(8);
  const [materials, setMaterials] = React.useState({
    posts,
    beams,
    boards,
    concrete,
    other,
  });
  const [slim, setSlim] = React.useState(true);
  const debouncedChangeHandler = React.useCallback(
    (e, s) => {
      if (e.target.value === "0") {
        let deleted = { ...sides };
        delete deleted[s[0]];
        setSides(deleted);
      } else {
        if (e.target.value === "") {
          setSides({ ...sides, [s[0]]: "" });
        } else {
          let event = parseFloat(e.target.value);
          if (event || event === 0) {
            setSides({ ...sides, [s[0]]: parseFloat(e.target.value) });
          }
        }
      }
    },
    [sides]
  );

  const getSections = (lf) => {
    if (lf % panelSize === 0) {
      return lf / panelSize;
    } else {
      return Math.ceil(lf / panelSize);
    }
  };

  let totalLF = Object.values(sides).reduce((acc, curr) => acc + curr, 0);
  let sideSections = Object.values(sides).map((s) => getSections(s));
  let totalPosts =
    sideSections.reduce((acc, curr) => acc + curr + 1, 0) - sharedPosts;
  let totalSections = sideSections.reduce((acc, curr) => acc + curr, 0);
  let prices = getPrices(materials);
  const postPrice = totalPosts * prices.concrete + totalPosts * prices.posts;
  const panelPrice =
    totalSections * prices.beams +
    totalSections * prices.other +
    totalSections * prices.boards;

  const total = postPrice + panelPrice;
  const hst = total * 1.13;

  const crewChargePerHour = 100;
  const jobToComplete = 48;
  const hoursToCompleteJob = 8;
  const labourPrice = (crewChargePerHour * hoursToCompleteJob) / jobToComplete;
  const ftPerHour = jobToComplete / hoursToCompleteJob;

  const crewPay = labourPrice * totalLF;
  const finalCost = crewPay + hst * 1.3;
  const costPerFt = finalCost / totalLF;
  const estimatedHoursToComplete = `${Math.floor(
    totalLF / ftPerHour / 2
  )} - ${Math.ceil(totalLF / ftPerHour / 1.5)}`;
  const costAfterTax = costPerFt * 1.13;
  const profit = finalCost - crewPay - hst;

  const weight = getWeights(materials, totalPosts);
  return (
    <>
      <div style={{ margin: "2em" }} className="danny">
        <div style={{ textAlign: "center" }}>
          <img src={sevenohfive} style={{ maxWidth: "100%" }} alt="logo.png" />
        </div>
        <Card>
          <div>
            <h1>Fence Sides</h1>
            {Object.entries(sides).map((s, idx) => {
              return (
                <TextField
                  type="number"
                  key={`${idx}-${s[0]}`}
                  label="Length of Fence"
                  value={s[1]}
                  onChange={(e) => debouncedChangeHandler(e, s)}
                />
              );
            })}

            <div>
              <Button
                onClick={() => {
                  setSides({ ...sides, [Object.values(sides).length + 1]: 8 });
                }}
                variant="contained"
              >
                +
              </Button>
            </div>

            <br />
            <div>
              <TextField
                type="number"
                label="Panel Size"
                value={panelSize}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setPanelSize("");
                  } else {
                    let event = parseFloat(e.target.value);

                    if (event || event === 0) {
                      setPanelSize(event);
                    }
                  }
                }}
              />
              <TextField
                label="Shared Posts"
                type="number"
                value={sharedPosts}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setSharedPosts("");
                  } else {
                    let event = parseFloat(e.target.value);
                    if (event || event === 0) {
                      setSharedPosts(event);
                    }
                  }
                }}
              />
              <div>
                <FormControlLabel
                  value="Slim"
                  control={
                    <Switch checked={slim} onChange={(e) => setSlim(!slim)} />
                  }
                  label="Show Total Price & Quantities"
                  labelPlacement="start"
                />
              </div>

              {Object.entries(fenceTypes).map((f) => (
                <Button
                  onClick={() => {
                    setMaterials(fenceTypes[f[0]]);
                  }}
                  key={`${f[0]}-button`}
                  variant="contained"
                >
                  {f[0]}
                </Button>
              ))}
            </div>
          </div>
        </Card>

        {materialsCategory.map((m, idx) => (
          <MatCard
            key={`${idx}-category`}
            m={m}
            setMaterials={setMaterials}
            totalPosts={totalPosts}
            totalSections={totalSections}
            materials={materials}
            slim={slim}
          />
        ))}

        <Card>
          <div>
            <h1>Totals:</h1>
            <FormControlLabel
              value="Redact"
              control={
                <Switch checked={redact} onChange={(e) => setRedact(!redact)} />
              }
              label="Redact"
              labelPlacement="start"
            />
            <div>
              <TextField label="Sections" value={totalSections} />
              <TextField label="Posts" value={totalPosts} />

              <TextField
                label="Estimated Hours"
                value={estimatedHoursToComplete}
              />

              <TextField label="Cost per Ft" value={costPerFt} />
              <TextField label="Sell For" color="success" value={finalCost} />
              <TextField label="Cost per FT after Tax" value={costAfterTax} />

              {!redact ? (
                <>
                  <TextField label="Mateial Cost with HST" value={hst} />
                  <TextField label="Labour Cost" value={crewPay} />
                  <TextField label="Business Profit" value={profit} />
                  <TextField label="Weight" value={weight} />
                </>
              ) : null}
            </div>
          </div>
        </Card>
      </div>
      <div style={{ textAlign: "center" }}>
        <DrawingArea />
      </div>
    </>
  );
}
