import useSWR from "swr";

export function useSettings() {
  const { data, error, isLoading } = useSWR(`/settings`);

  return {
    settings: data,
    isLoading,
    error,
  };
}
