import { useParams } from "@reach/router";
import { useState } from "react";
import { useDesigns } from "../../hooks/useDesigns";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import { sumNumbers } from "./Projects";
import { useReducer } from "react";
import { v4 } from "uuid";
import { api } from "../../util/api";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import DeleteIcon from "@mui/icons-material/Delete";

const { Card, Button, Grid, TextField } = require("@mui/material");

const Quote = ({ costPerFt, pricePerFtBeforeTax }) => {
  const { projectId } = useParams();
  const { designs, isLoading, error } = useDesigns();
  const {
    project,
    isLoading: projectIsLoading,
    error: projectError,
    mutate,
  } = useProject(projectId);
  const { teamIsLoading, team, error: errorLoading } = useTeam();
  const [removal, setRemoval] = useState(false);

  if (isLoading || projectIsLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }
  const design = designs.find((design) => design.id === project.designId);

  const totalCost =
    sumNumbers(Object.values(project.lengths)) * pricePerFtBeforeTax;
  const lengths = sumNumbers(Object.values(project.lengths));

  let removalCost = 0;
  if (removal) {
    removalCost = team.team.removalCostPerFt * lengths;
  }

  const extrasCost = sumNumbers(
    project?.extras?.map((e) => e.price * e.quantity) || []
  );

  const subtotal = totalCost + extrasCost + removalCost;
  const tax = subtotal * (team.team.stateTax / 100 + 1);

  return (
    <Card>
      <Button onClick={() => setRemoval(!removal)}>Toggle Removal</Button>
      <Extras />
      <Card>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={team.team.logo} style={{ width: "35%" }} />
            <h3>{team.team.website}</h3>
          </div>
          <div>
            <h3>Estimate:</h3>
            <p>{new Date().toDateString()}</p>
            <h5>Prepared For:</h5>
            <div>
              <p>
                {project.clientFirstName} {project.clientLastName}
              </p>
              <p>{project.clientPhone}</p>
              <p>{project.address}</p>
              <p>{project.city}</p>
            </div>
            <div>
              <img
                src={design.image}
                style={{ width: "100%", maxWidth: "400px" }}
              />
            </div>
            <Grid container>
              <Grid item xs={12}>
                <p>{design.name} </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  ${pricePerFtBeforeTax} x {lengths}ft
                </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${totalCost.toFixed(2)}</p>
              </Grid>
            </Grid>

            {project.extras?.length
              ? project.extras.map((extra) => {
                return (
                  <Grid container>
                    <Grid item xs={12}>
                      <p>{extra.item} </p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        ${extra.price} x {extra.quantity}
                      </p>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}>
                      <p>${(extra.price * extra.quantity).toFixed(2)}</p>
                    </Grid>
                  </Grid>
                );
              })
              : null}

            {removal ? (
              <Grid container>
                <Grid item xs={12}>
                  <p>Removal and Disposal </p>
                </Grid>
                <Grid item xs={6}>
                  <p>
                    ${team.team.removalCostPerFt} x {lengths}ft
                  </p>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <p>${removalCost.toFixed(2)}</p>
                </Grid>
              </Grid>
            ) : null}

            <Grid container>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}>
                <p>Subtotal: </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${subtotal.toFixed(2)}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}>
                <p>
                  {team.team.stateTax}% {team.team.state} Tax:
                </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${tax.toFixed(2)}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Card>
  );
};

const initState = [{ id: "1", item: "", price: "", quantity: "" }];

function reducer(state, action) {
  if (action.type === "addBlank") {
    return [...state, { id: v4(), item: "", price: "", quantity: "" }];
  }
  if (action.type === "removeItem") {
    console.log(state[0].id, action.payload)
    return state.filter((item) => item.id !== action.payload);
  }

  if (action.type === "updateItem") {
    return state.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, ...action.payload };
      }
      return item;
    });
  }
}

const Extras = () => {
  const { projectId } = useParams();

  const {
    project,
    isLoading: projectIsLoading,
    error: projectError,
    mutate,
  } = useProject(projectId);
  const [extras, dispatch] = useReducer(reducer, project.extras || []);

  if (projectIsLoading) {
    return <div className="spinner" />;
  }

  const saveRow = () => {
    api("PUT", `/project/${projectId}`, { extras });
    mutate({ ...project, extras });
  };

  return (
    <Card>
      <div>
        {extras.map((e) => (
          <QuoteRow
            extra={e}
            dispatch={dispatch}
            project={project}
            mutate={mutate}
          />
        ))}
        <div>
          <Button
            variant="contained"
            onClick={() => dispatch({ type: "addBlank" })}
          >
            <PlaylistAddIcon />
          </Button>
          <Button variant="contained" onClick={saveRow}>
            Save
          </Button>
        </div>
      </div>
    </Card>
  );
};

const QuoteRow = ({ extra, dispatch, mutate, project }) => {
  return (
    <div>
      <TextField
        value={extra.item}
        label="Item"
        onChange={(e) => {
          dispatch({
            type: "updateItem",
            payload: { id: extra.id, item: e.target.value },
          });
        }}
      />
      <TextField
        value={extra.price}
        label="Price ($)"
        onChange={(e) => {
          dispatch({
            type: "updateItem",
            payload: { id: extra.id, price: e.target.value },
          });
        }}
      />
      <TextField
        value={extra.quantity}
        label="Quantity"
        onChange={(e) => {
          dispatch({
            type: "updateItem",
            payload: { id: extra.id, quantity: e.target.value },
          });
        }}
      />
      <Button
        variant="contained"
        onClick={() => {

          // api("PUT")
          console.log(extra.id)
          dispatch({ type: "removeItem", payload: extra.id });
        }}
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

export default Quote;
