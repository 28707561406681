export const chainMaterials = {
  posts: {
    black: {
      "1-1/2": {
        // "6.5ft": 18.82,
        "7.5ft": 17.48,
      },

      "1-7/8": {
        // "6.5ft": 23.17,
        "7.5ft": 21.98,
        // "8.5ft": 40.88,
      },
      // "2-3/8": {
      // "8.5ft": 52.84,
      // "9.5ft": 72,
      // "10.5ft": 80,
      // },

      // "3-1/2": {
      // "9.5ft": 132.09,
      // "10.5ft": 140,
      // },
    },
    galavanized: {
      "1-1/2": {
        //     "6.5ft": 17.8,
        "7.5ft": 12.98,
      },
      "1-7/8": {
        // "6.5ft": 21.86,
        "7.5ft": 18.98,
        // "8.5ft": 27.68,
      },
      //   "2-3/8": {
      //     "8.5ft": 48.9,
      //     "9.5ft": 54.9,
      //     "10.5ft": 60.9,
      //   },
      //   "3-1/2": {
      //     "10.5ft": 105,
      //   },
    },
  },

  mesh: {
    black: {
      "1.5 Inch": {
        "4ft": 180,
        "5ft": 180,
        // "6ft": 420,
      },
      "2 Inch 11GA": {
        "4ft": 124,
        "5ft": 180,

        // "5ft": 125,
        // "6ft": 196,
      },
      // "2 Inch 9GA": {
      //   "4ft": 191,
      //   "5ft": 239,
      //   "6ft": 287,
      // },
    },

    galavanized: {
      // "1.5 Inch": {
      //   "4ft": 380,
      //   "5ft": 178,
      //   "6ft": 570,
      // },
      "2 Inch 11GA": {
        "4ft": 148,
        "5ft": 178,
        // "6ft": 231,
      },
      // "2 Inch 9GA": {
      //   "4ft": 226,
      //   "5ft": 282,
      //   "6ft": 339,
      // },
    },
  },
  bottomWire: {
    black: {
      "100ft": 7,
    },
    galavanized: {
      "100ft": 8,
    },
  },
  topRailBand: {
    black: {
      "1-7/8": 0.01,
      // "2-3/8": 1.42,
      // "3-1/2": 1.75,
    },
    galavanized: {
      "1-7/8": 0.01,
      // "2-3/8": 1.42,
      // "3-1/2": 1.75,
    },
  },
  fenceTies: {
    black: {
      "1-7/8": 0.18,
      // "2-3/8": 0.15,
      // "3-1/2": 0.15,
      // "6-1/2": 0.11,
      // "8-1/4": 0.15,
    },
    galavanized: {
      "1-7/8": 0.14,
      // "2-3/8": 0.19,
      // "3-1/2": 0.19,
      // "6-1/2": 0.15,
      // "8-1/4": 0.19,
    },
  },
  topRail: {
    black: {
      "1-1/4": 23.98,
      // "1-11/16": 122.61,
    },
    galavanized: {
      "1-1/4": 15.4,
      //  "1-11/16": 85.75
    },
  },
  topRailSleeve: {
    black: {
      "1-11/16": 3.89,
    },
    galavanized: {
      "1-11/16": 3.39,
    },
  },
  nuts: {
    black: {
      "1-7/8": 0.35,
      "2-3/8": 0.4,
      "3-1/2": 0.4,
      commercial: 0.4,
    },
    galavanized: {
      "1-7/8": 0.17,
      "2-3/8": 0.45,
      "3-1/2": 0.45,
      commercial: 0.45,
      residential: 0.17,
    },
  },
  tensionBand: {
    black: {
      "1-7/8": 1.0,
      // "2-3/8": 1.7,
      // "3-1/2": 2.1,
    },
    galavanized: {
      "1-7/8": 0.77,
      // "2-3/8": 1.7,
      // "3-1/2": 2.1,
    },
  },
  railEnd: {
    black: {
      "1-1/4": 2.35,
      // "1-11/16": 2.69,
    },
    galavanized: {
      "1-1/4": 2.25,
      // "1-11/16": 2.39,
    },
  },
  mainPostCap: {
    black: {
      // "1-11/16": 1.19,
      "1-7/8": 1.88,
      // "2-3/8": 2.2,
      // "3-1/2": 3.99,
    },
    galavanized: {
      // "1-11/16": 0.89,
      "1-7/8": 1.25,
      // "2-3/8": 1.69,
      // "3-1/2": 3.49,
    },
  },
  linePostCap: {
    black: {
      "1-1/2": 1.98,
      // "1-7/8": 3.29,
      // "2-3/8": 3.99,
    },
    galavanized: {
      "1-1/2": 1.75,
      // "1-7/8": 2.39,
      // "2-3/8": 2.99,
    },
  },
  tensionBar: {
    black: {
      "4ft": 2.98,
      "5ft": 4.6,
      // "6ft": 5.99,
    },
    galavanized: {
      "4ft": 3.5,
      "5ft": 5.4,
      // "6ft": 7.14,
    },
  },
  "barbedWire 2-3/8 Arm": 6.89,
};

export const getPosts = (lf, panelSize) => {
  if (lf % panelSize === 0) {
    return lf / panelSize + 1;
  } else {
    return Math.ceil(lf / panelSize);
  }
};
