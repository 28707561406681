import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import {
  chainMaterials,
  getPosts,
  //  getSections
} from "./chainlink";

const Chain = () => {
  const [sides, setSides] = React.useState({ 1: 50 });
  // const [redact, setRedact] = React.useState(true);
  // const [slim, setSlim] = React.useState(true);
  // const [sharedPosts, setSharedPosts] = React.useState(0);
  const [
    panelSize,
    //  setPanelSize
  ] = React.useState(8);
  // const [materials, setMaterials] = React.useState([]);
  const [colour, setColour] = React.useState("black");
  const [height, setHeight] = React.useState("5ft");
  const [mainPostWidth, setMainPostWidth] = React.useState("1-7/8");
  const [mainPostLength, setMainPostLength] = React.useState("7.5ft");
  const [linePostWidth, setLinePostWidth] = React.useState("1-1/2");
  const [linePostLength, setLinePostLength] = React.useState("7.5ft");
  const [mesh, setMesh] = React.useState("2 Inch 11GA");
  const [topRailSize, setTopRailSize] = React.useState("1-1/4");

  const debouncedChangeHandler = React.useCallback(
    (e, s) => {
      if (e.target.value === "0") {
        let deleted = { ...sides };
        delete deleted[s[0]];
        setSides(deleted);
      } else {
        if (e.target.value === "") {
          setSides({ ...sides, [s[0]]: "" });
        } else {
          let event = parseFloat(e.target.value);
          if (event || event === 0) {
            setSides({ ...sides, [s[0]]: parseFloat(e.target.value) });
          }
        }
      }
    },
    [sides]
  );

  let totalLF = Object.values(sides).reduce((acc, curr) => acc + curr, 0);
  // let totalPosts = Object.values(sides).map((s) => getPosts(s, panelSize));
  let totalMainPosts = Object.values(sides).reduce((acc) => acc + 2, 0);
  let totalLinePosts = Object.values(sides).reduce(
    (acc, curr) => getPosts(curr, panelSize) - 2 + acc,
    0
  );

  // let linePostCaps = totalLinePosts;
  // let mainPostCaps = totalMainPosts;
  let tensionBars = Object.values(sides).reduce((acc) => acc + 2, 0);
  let tensionBands = Object.values(sides).reduce((acc, curr, index) => {
    return parseFloat(height[0]) * 2 + acc;
  }, 0);
  let topRailEnd = Object.values(sides).reduce((acc) => acc + 2, 0);
  let nutAndBolt = tensionBands + topRailEnd;
  let topRail = Object.values(sides).reduce(
    (acc, s) => getPosts(s, 10) + acc,
    0
  );
  let fenceTies = totalLF * 1.9;
  let chainlinkMesh = totalLF / 50;
  let bottomWire = totalLF / 100;

  const mainPostCost =
    totalMainPosts *
    chainMaterials["posts"][colour][mainPostWidth][mainPostLength];
  const linePostCost =
    totalMainPosts *
    chainMaterials["posts"][colour][linePostWidth][linePostLength];

  const mainPostCapsCost =
    totalMainPosts * chainMaterials["mainPostCap"][colour][mainPostWidth];
  const linePostCapsCost =
    totalLinePosts * chainMaterials["linePostCap"][colour][linePostWidth];

  const tensionBarsCost =
    tensionBars * chainMaterials["tensionBar"][colour][height];

  const tensionBandsCost =
    tensionBands * chainMaterials["tensionBand"][colour][mainPostWidth];

  const nutAndBoltCost =
    nutAndBolt * chainMaterials["nuts"][colour][mainPostWidth];

  const topRailCost = topRail * chainMaterials["topRail"][colour][topRailSize];

  const topRailEndCost =
    topRailEnd * chainMaterials["railEnd"][colour][topRailSize];
  const topRailBandCost =
    topRailEnd * chainMaterials["topRailBand"][colour][mainPostWidth];

  const fenceTiesCost =
    fenceTies * chainMaterials["fenceTies"][colour][mainPostWidth];

  const meshCost = chainlinkMesh * chainMaterials["mesh"][colour][mesh][height];
  const bottomWireCost =
    bottomWire * chainMaterials["bottomWire"][colour]["100ft"];

  const totalCost =
    mainPostCost +
    linePostCost +
    mainPostCapsCost +
    linePostCapsCost +
    tensionBarsCost +
    tensionBandsCost +
    nutAndBoltCost +
    topRailCost +
    topRailEndCost +
    topRailBandCost +
    fenceTiesCost +
    meshCost +
    bottomWireCost;

  const materialCost = totalCost * 1.13;

  const totalCostWithLabour = materialCost * 1.15 + totalLF * 15;
  const costPerFt = totalCostWithLabour / totalLF;

  const afterTax = totalCostWithLabour * 1.13;

  const afterTaxPerFt = afterTax / totalLF;

  return (
    <div>
      <h1>Chainlink</h1>
      <Card>
        <div>
          <h2>Sides</h2>
          <div>
            {Object.entries(sides).map((s, idx) => {
              return (
                <TextField
                  type="number"
                  key={`${idx}-${s[0]}`}
                  label="Length of Fence"
                  value={s[1]}
                  onChange={(e) => debouncedChangeHandler(e, s)}
                />
              );
            })}
          </div>

          <div>
            <Button
              onClick={() => {
                setSides({ ...sides, [Object.values(sides).length + 1]: 8 });
              }}
              variant="contained"
            >
              +
            </Button>
          </div>
        </div>
      </Card>
      <Card>
        <div>
          <h2>Options</h2>
          <FormControl fullWidth>
            <InputLabel>Colour</InputLabel>
            <Select
              value={colour}
              label="Colour"
              onChange={(event) => {
                setColour(event.target.value);
              }}
            >
              <MenuItem value={"black"}>Black</MenuItem>
              <MenuItem value={"galavanized"}>Galavanized</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Height</InputLabel>
            <Select
              value={height}
              label="Height"
              onChange={(e) => {
                setHeight(e.target.value);
              }}
            >
              <MenuItem value={"4ft"}>4ft</MenuItem>
              <MenuItem value={"5ft"}>5ft</MenuItem>
              <MenuItem value={"6ft"}>6ft</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Mesh</InputLabel>
            <Select
              value={mesh}
              label="Mesh"
              onChange={(e) => setMesh(e.target.value)}
            >
              <MenuItem value={"1.5 Inch"}>1.5 Inch</MenuItem>
              <MenuItem value={"2 Inch 11GA"}>2 Inch Residential</MenuItem>
              {/* <MenuItem value={"2 Inch 9GA"}>2 Inch Commercial</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Main Post Width</InputLabel>
            <Select
              value={mainPostWidth}
              label="Post Size"
              onChange={(e) => setMainPostWidth(e.target.value)}
            >
              <MenuItem value={"1-7/8"}>1-7/8</MenuItem>
              {/* <MenuItem value={"2-3/8"}>2-3/8</MenuItem>
              <MenuItem value={"3-1/2"}>3-1/2</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Main Post Length</InputLabel>
            <Select
              value={mainPostLength}
              label="Main Post Length"
              onChange={(e) => setMainPostLength(e.target.value)}
            >
              {Object.keys(chainMaterials.posts[colour][mainPostWidth]).map(
                (l, idx) => {
                  return (
                    <MenuItem key={`main-post-${l}-${idx}`} value={l}>
                      {l}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Line Post Width</InputLabel>
            <Select
              value={linePostWidth}
              label="Post Size"
              onChange={(e) => setLinePostWidth(e.target.value)}
            >
              <MenuItem value={"1-1/2"}>1-1/2</MenuItem>
              {/* <MenuItem value={"1-7/8"}>1-7/8</MenuItem>
              <MenuItem value={"2-3/8"}>2-3/8</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Line Post Length</InputLabel>
            <Select
              value={linePostLength}
              label="Line Post Size"
              onChange={(e) => setLinePostLength(e.target.value)}
            >
              {chainMaterials.posts[colour][linePostWidth]
                ? Object.keys(chainMaterials.posts[colour][linePostWidth]).map(
                    (l, idx) => {
                      return (
                        <MenuItem key={`line-post-${l}-${idx}`} value={l}>
                          {l}
                        </MenuItem>
                      );
                    }
                  )
                : null}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Top Rail</InputLabel>
            <Select
              value={topRailSize}
              label="Top Rail Size"
              onChange={(e) => setTopRailSize(e.target.value)}
            >
              {chainMaterials["topRail"][colour]
                ? Object.keys(chainMaterials["topRail"][colour]).map(
                    (l, idx) => {
                      return (
                        <MenuItem key={`top-rail-${l}-${idx}`} value={l}>
                          {l}
                        </MenuItem>
                      );
                    }
                  )
                : null}
            </Select>
          </FormControl>
        </div>
      </Card>
      <Card>
        <div>
          <h1>Materials</h1>
          <p>
            Main Posts: {totalMainPosts} = ${mainPostCost}
          </p>
          <p>
            Main Post Caps: {totalMainPosts} = ${mainPostCapsCost}
          </p>
          <p>
            Line Posts: {totalLinePosts} = ${linePostCost}
          </p>
          <p>
            Line Post Caps: {totalLinePosts} = ${linePostCapsCost}
          </p>
          <p>
            Tension Bars: {tensionBars} = ${tensionBarsCost}
          </p>
          <p>
            Tension Bands: {tensionBands} = ${tensionBandsCost}
          </p>
          <p>
            Nuts & Bolts: {nutAndBolt} = ${nutAndBoltCost}
          </p>
          <p>
            Top Rail: {topRail} = ${topRailCost}
          </p>
          <p>
            Top Rail End Cups: {topRailEnd} = ${topRailEndCost}
          </p>
          <p>
            Top Rail Band: {topRailEnd} = ${topRailBandCost}
          </p>
          <p>
            Fence Ties: {fenceTies} = ${fenceTiesCost}{" "}
          </p>
          <p>
            Chainlink Mesh: {chainlinkMesh} = ${meshCost}
          </p>
          <p>
            Bottom Wire Cost: {bottomWire} = ${bottomWireCost}
          </p>
          <p>Total Material Cost: ${materialCost}</p>
          <p>Total Cost with Labour: ${totalCostWithLabour}</p>
          <p>After Tax: ${afterTax}</p>
          <p>Cost Per Ft: ${costPerFt}</p>
          <p>After Tax per Ft: ${afterTaxPerFt}</p>
        </div>
      </Card>
    </div>
  );
};

export default Chain;
