import { useProjects } from "../../hooks/useProjects";
import { getStackUtilityClass, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { api } from "../../util/api";
import { navigate } from "@reach/router";
import { useTeam } from "../../hooks/useTeam";
import camelToTitle from "../../util/case";
import { useState } from "react";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { statuses } from "./Notes";

const Projects = () => {
  let { projects, isLoading, error } = useProjects();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();
  const [projectFilter, setProjectFilter] = useState(team.permissions.includes("createProject") ? "all" : "ready")

  const { enqueueSnackbar } = useSnackbar();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const createProject = async () => {
    enqueueSnackbar("Creating Project...");

    const addProject = await api("POST", `/project`, {
      name: new Date().toLocaleString(),
    });

    navigate(`/project/${addProject.id}`);
    enqueueSnackbar("Created", { variant: "success" });
  };


  projects = projectFilter === "all" ? projects : projects.filter(project => {
    return project.status === projectFilter
  })




  return (
    <Card>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >

          <Grid container>
            <Grid item xs={6} lg={6}>
              <div>
                <h1>Projects</h1>
              </div>
            </Grid>
            {team.permissions.includes("createProject") ? (
              <Grid item xs={6} lg={6}>

                <h1 style={{ display: "flex", justifyContent: "right" }}>
                  <Button variant="contained" onClick={createProject}>
                    Add
                  </Button></h1>
              </Grid>
            ) : null}
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={projectFilter}
                  label="Status"
                  onChange={(event) => {
                    setProjectFilter(event.target.value);
                  }}
                >
                  {statuses.map(s => {
                    return <MenuItem key={s} value={s}>{camelToTitle(s)}</MenuItem>
                  })}

                  <MenuItem value={"all"}>All</MenuItem>

                </Select>
              </FormControl>
            </Grid>




          </Grid>




        </div>
        {projects.length === 0 ? "No Projects Yet" : null}
        {projects
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map((p) => (
            <Project key={p.id} project={p} team={team} />
          ))}
      </div>
    </Card>
  );
};

export function sumNumbers(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    const num = parseFloat(arr[i]);
    if (!isNaN(num)) {
      sum += num;
    }
  }
  return sum;
}

const Project = ({ project, team }) => {
  // if (
  //   !project.clientFirstName
  //   // ||
  //   // !project.clientLastName ||
  //   // !project.address ||
  //   // !project.city ||
  //   // !project.lengths
  // ) {
  //   return (
  //     <Card
  //       onClick={() => {
  //         navigate(`/project/${project.id}`);
  //       }}
  //     >
  //       <div>
  //         <p>
  //           Project is Missing Client First Name,
  //           {!team.permissions.includes("editProject")
  //             ? " Have an Owner or Quoter update the project"
  //             : " Click here and Fill in some data"}
  //         </p>
  //       </div>
  //     </Card>
  //   );
  // }



  const getStyle = (status) => {
    switch (status) {
      case "notQuoted":
        return { color: "red" }
      case "quoted":
        return { color: "blue" }
      case "noAnswer":
        return { color: "grey" }
      case "onHold":
        return { color: "yellow" }
      case "ready":
        return { color: "green" }
      case "done":
        return { color: "black" }
      default:
        return { color: "red" }
    }
  }

  return (
    <Card
      onClick={() => {
        navigate(`/project/${project.id}`);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >

        <Grid container>
          <Grid item xs={6} md={4}>
            <h2>
              {project.clientFirstName ? project.clientFirstName : "N/A"}{" "}
              {project.clientLastName}
            </h2>
          </Grid>


          <Grid item xs={6} md={4}>


            <h2 style={getStyle(project.status)}>
              {camelToTitle(project.status ? project.status : "noStatus")}
            </h2>
          </Grid>

          <Grid item xs={6} md={4}>
            <h2>

              {project.lengths
                ? `${sumNumbers(Object.values(project.lengths))} ft`
                : "N/A"}
            </h2>

          </Grid>


        </Grid>




      </div>
      <div>
        <p>{project.address ? project.address : "N/A"}</p>
        <p>{project.city ? project.city : "N/A"}</p>
      </div>
    </Card>
  );
};

export default Projects;
