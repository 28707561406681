import { API, Auth } from "aws-amplify";

export const api = async (method, path, body) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  if (method === "POST") {
    return await API.post("backend", path, {
      headers: {
        authorization: `${token}`,
      },
      body,
    });
  }

  if (method === "PUT") {
    return await API.put("backend", path, {
      headers: {
        authorization: `${token}`,
      },
      body,
    });
  }

  if (method === "DELETE") {
    return await API.del("backend", path, {
      headers: {
        authorization: `${token}`,
      },
      body,
    });
  }
};
