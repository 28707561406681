import React, { useState } from "react";
import { render } from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { SWRConfig } from "swr";
import { SnackbarProvider } from "notistack";
import { useSettings } from "./hooks/useSettings";
import NewCompany from "./pages/NewCompany";
import { fetcher } from "./util/fetcher";
import ButtonAppBar from "./comp/NavBar";
import { LocationProvider } from "@reach/router";
import { MainRouter } from "./Router";
import { Card, Button } from "@mui/material";
// import mapFeature from "./mapFeature.png";
Amplify.configure({
  Auth: {
    // identityPoolId: xx-xxxx-x:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx,
    region: "ca-central-1",
    userPoolId: "ca-central-1_1aSXOUfM1",
    userPoolWebClientId: "1f28kurgu26io05qlq8fvqq2gl",
  },
  API: {
    endpoints: [
      {
        name: "backend",
        endpoint: process.env.REACT_APP_ENDPOINT,
      },
    ],
  },
});

const Switch = () => {
  const { settings, error, isLoading } = useSettings();

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (settings.sk === "no-team") {
    return <NewCompany />;
  }

  return <MainRouter />;
};

const Login = () => {
  const [ready, setReady] = useState(true);
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user?.signInUserSession) {
    return (
      <>
        <ButtonAppBar />
        <Card>
          {!ready ? (
            <Card>
              <div>
                <h1>Quote Fences Faster</h1>
                <h2>- Dynamic Price per Ft</h2>
                <h2>- Save Map Layouts</h2>
                <h2>- Create and Compare Different Styles</h2>
                <h2>- Add Team Members</h2>
                <p>* Wood Fences, Metal, Vinyl Supported</p>
                <p>* Chainlink (Coming Soon)</p>
                <p>* Email Quotes (Coming Soon)</p>

                <p>* FenceWiz.com is in Beta</p>
                <Button variant="contained" onClick={() => setReady(true)}>
                  Sign in/Register
                </Button>
              </div>
            </Card>
          ) : null}
          {ready ? (
            <Card>
              <Authenticator
                // socialProviders={["google"]}
                loginMechanisms={["email"]}
                signUpAttributes={[
                  "email",
                  "given_name",
                  "family_name",
                  "address",
                  "birthdate",
                  "phone_number",
                ]}
              />
            </Card>
          ) : null}
        </Card>
      </>
    );
  }

  return (
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnMount: true,
        refreshInterval: 0,
        fetcher,
      }}
    >
      <Switch />
    </SWRConfig>
  );
};

render(
  <React.StrictMode>
    <SnackbarProvider
      classes={{ containerRoot: "z-alert" }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2500}
    >
      <LocationProvider>
        <Authenticator.Provider>
          <Login />
        </Authenticator.Provider>
      </LocationProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
