import { Button, Card, TextField as TextFieldMui } from "@mui/material";
import { useTeam } from "../../hooks/useTeam";
import { api } from "../../util/api";
import { useCallback } from "react";
import { sumNumbers } from "./Projects";
import Map from "./Map";
import AddIcon from "@mui/icons-material/Add";

export const Lengths = ({ project, mutate }) => {
  // const [sides, setSides] = useState(project?.lengths || []);
  const { isLoading: teamIsLoading, team } = useTeam();

  const lengthCallback = useCallback(
    async (e, s) => {
      if (e.target.value === "0") {
        let deleted = { ...project.lengths };
        delete deleted[s[0]];
        // setSides(deleted);
        api("PUT", `/project/${project.id}`, { lengths: deleted });
        mutate({ ...project, lengths: deleted }, { revalidate: false });
      } else {
        if (e.target.value === "") {
          mutate(
            { ...project, lengths: { ...project.lengths, [s[0]]: "" } },
            { revalidate: false }
          );
          api("PUT", `/project/${project.id}`, {
            lengths: { ...project.lengths, [s[0]]: "" },
          });
        } else {
          let event = parseFloat(e.target.value);
          if (event || event === 0) {
            mutate(
              {
                ...project,
                lengths: {
                  ...project.lengths,
                  [s[0]]: parseFloat(e.target.value),
                },
              },
              { revalidate: false }
            );
            api("PUT", `/project/${project.id}`, {
              lengths: {
                ...project.lengths,
                [s[0]]: parseFloat(e.target.value),
              },
            });
            mutate(
              {
                ...project,
                lengths: {
                  ...project.lengths,
                  [s[0]]: parseFloat(e.target.value),
                },
              },
              { revalidate: false }
            );
          }
        }
      }
    },
    [project, mutate]
  );

  if (teamIsLoading) {
    return <div className="spinner" />;
  }

  // const saveLengthsButton = async (sides) => {
  //   enqueueSnackbar("Saving Project Lengths");
  //   await api("PUT", `/project/${project.id}`, { lengths: sides });
  //   await mutate();
  //   enqueueSnackbar("Saved Project Lengths", { variant: "success" });
  // };

  const addButton = () => {
    let s = {
      ...project.lengths,
      [Object.values(project.lengths || {}).length + 1]: 8,
    };
    api("PUT", `/project/${project.id}`, {
      lengths: s,
    });
    mutate({ ...project, lengths: s }, { revalidate: false });
  };

  return (
    <Card>
      <div>
        <h1>Lengths</h1>
        <Map />

        {project.lengths &&
          Object.entries(project.lengths).map((s, idx) => {
            return (
              <TextFieldMui
                type="number"
                key={`${idx}-${s[0]}`}
                label="Length in Ft"
                value={s[1]}
                onChange={(e) => lengthCallback(e, s)}
              />
            );
          })}
        <div>
          {team.permissions.includes("editProject") && (
            <Button onClick={addButton} variant="contained">
              <AddIcon />
            </Button>
          )}
          <h4>
            Total Length: {sumNumbers(Object.values(project.lengths || {}))}
          </h4>
        </div>
      </div>
      <SharedPosts project={project} mutate={mutate} />
    </Card>
  );
};

const SharedPosts = ({ project, mutate }) => {
  return (
    <div>
      <h2>Shared Posts</h2>
      <TextFieldMui
        type="number"
        label="Shared Posts"
        value={project?.sharedPosts}
        onChange={(e) => {
          api("PUT", `/project/${project.id}`, {
            sharedPosts: e.target.value,
          });
          mutate(
            { ...project, sharedPosts: e.target.value },
            { revalidate: false }
          );
        }}
      />
    </div>
  );
};
