import MapWithMarkers from "../../DrawingArea";

const Map = () => {
  return (
    <>
      {" "}
      <MapWithMarkers />
    </>
  );
};

export default Map;
