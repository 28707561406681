import useSWR from "swr";

export function useDesigns() {
  const { data, error, isLoading, mutate } = useSWR(`/team/designs`);

  return {
    designs: data,
    isLoading,
    error,
    mutate,
  };
}
