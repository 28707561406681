import { Button, Card, TextField } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import HideImageIcon from "@mui/icons-material/HideImage";
const DesignConfig = ({ designPageState, dispatch }) => {
  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // setOriginalImage(event.target.result);
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        // setWidth(targetWidth);
        // setHeight(targetHeight);

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        }).then((resizedBitmap) => {
          let quality = 0.99;
          let canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length > 1000000) {
            quality -= 0.01;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          // console.log(dataUrl);
          dispatch({ type: "updateImage", payload: dataUrl });
          // setResizedImage(dataUrl);
        });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    dispatch({ type: "updateImage", payload: "" });
  };

  return (
    <Card>
      <div>
        <h2>Panel Config </h2>
        <TextField
          label="Design Name:"
          value={designPageState.name}
          onChange={(e) =>
            dispatch({ type: "updateDesignName", payload: e.target.value })
          }
        />
        <TextField
          label="Length of Section:"
          value={designPageState.lengthOfSection}
          onChange={(e) =>
            dispatch({ type: "updateLengthOfSection", payload: e.target.value })
          }
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={designPageState.image}
            style={{
              maxWidth: "35vw",
            }}
          />
          <div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="config-setup"
              onChange={handleImageChange}
            />
            <label htmlFor="config-setup">
              <Button component="span" variant="contained">
                <ImageIcon />
              </Button>
            </label>
          </div>
          {designPageState.image ? (
            <Button variant="contained" onClick={removeImage}>
              <HideImageIcon />{" "}
            </Button>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default DesignConfig;
