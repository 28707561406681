import { Button, Card, Grid } from "@mui/material";
import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import camelToTitle from "../../util/case";
import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import Save from "@mui/icons-material/Save";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useState } from "react";

export const statuses = [
    "notQuoted",
    "quoted",
    "noAnswer",
    "onHold",
    "ready",
    "done",
];

export const Notes = () => {
    const { projectId } = useParams();
    const { project, isLoading, error, mutate } = useProject(projectId);

    const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

    if (isLoading || teamIsLoading) {
        return <div className="spinner" />;
    }

    if (error || teamError) {
        return (
            <p>
                Error! please refresh the page, if the problem persists contact
                fencewizapp@gmail.com
            </p>
        );
    }

    const saveClientButton = async (values) => {
        enqueueSnackbar("Saving Client Details");
        await api("PUT", `/project/${projectId}`, values);
        await mutate();
        enqueueSnackbar("Notes Updated", { variant: "success" });
    };

    const handleChangeStatus = async (e) => {
        enqueueSnackbar("Saving Client Details");
        await api("PUT", `/project/${projectId}`, { status: e.target.value });
        await mutate();
        enqueueSnackbar("Status Updated", { variant: "success" });
    };

    const { notes, status } = project;

    const handleImageChange = (e) => {

        if (e.target.files[0]) {
            const file = e.target.files[0];
            enqueueSnackbar("Optimizing Picture");

            const reader = new FileReader();

            reader.onload = async (event) => {
                const img = new Image();
                img.onload = () => {
                    // setOriginalImage(event.target.result);
                    const aspectRatio = img.width / img.height;
                    const maxWidth = 2000;
                    const maxHeight = 2000;

                    let targetWidth;
                    let targetHeight;

                    if (img.width > maxWidth || img.height > maxHeight) {
                        if (img.width > img.height) {
                            targetWidth = maxWidth;
                            targetHeight = maxWidth / aspectRatio;
                        } else {
                            targetHeight = maxHeight;
                            targetWidth = maxHeight * aspectRatio;
                        }
                    } else {
                        targetWidth = img.width;
                        targetHeight = img.height;
                    }

                    // setWidth(targetWidth);
                    // setHeight(targetHeight);

                    createImageBitmap(img, {
                        resizeWidth: targetWidth,
                        resizeHeight: targetHeight,
                    })
                        .then(async (resizedBitmap) => {
                            let quality = 0.99;
                            let canvas = document.createElement("canvas");
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            const ctx = canvas.getContext("2d");
                            ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
                            let dataUrl = canvas.toDataURL("image/jpeg", quality);
                            console.log(dataUrl.length);
                            while (dataUrl.length > 1000000) {
                                console.log(dataUrl.length);
                                quality -= 0.01;
                                dataUrl = canvas.toDataURL("image/jpeg", quality);
                            }

                            enqueueSnackbar("Uploading Picture...");

                            const newImage = await api("POST", `/project/${projectId}/image`, {
                                image: dataUrl,
                            });
                            enqueueSnackbar("Upload Complete", { variant: "success" });

                            mutate(newImage, { revalidate: false });
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                };

                img.src = event.target.result;
            };

            reader.readAsDataURL(file);
        }

    };

    return (
        <Card>
            <div>
                <h1>Notes</h1>

                <Formik
                    initialValues={{
                        notes,
                        status,
                    }}
                    onSubmit={saveClientButton}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            // width: "100%"
                                        }}
                                    >
                                        <Field
                                            component={TextField}
                                            name={"notes"}
                                            label={"Notes"}
                                            multiline
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div>
                                        {isSubmitting ? (
                                            <div className="spinner" />
                                        ) : team.permissions.includes("editProject") ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                            >
                                                <Save />
                                            </Button>
                                        ) : null}
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>

                <FormControl fullWidth>
                    <InputLabel id="status" disabled={!team.permissions.includes("editProject")}
                    >Status</InputLabel>
                    <Select
                        labelId="status"
                        id="statusId"
                        value={status}
                        label="Status"
                        onChange={handleChangeStatus}
                    >
                        {statuses.map((s) => {
                            return (
                                <MenuItem key={s} value={s}>
                                    {camelToTitle(s)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <Grid container>
                {project.images &&
                    Object.entries(project.images).map((i) => {
                        return (
                            <ImageButtons image={i[1]} imageId={i[0]} />
                        );
                    })}
            </Grid>

            <div>
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                    <Button component="span" variant="contained">
                        <AddPhotoAlternateIcon />
                    </Button>
                </label>
            </div>
        </Card>
    );
};


const ImageButtons = ({ image, imageId }) => {
    const { projectId } = useParams();

    const { mutate } = useProject(projectId);
    const { team } = useTeam()
    const [areYouSure, setAreYouSure] = useState(false);

    const deletePictureButton = async () => setAreYouSure(true);

    const areYouSureButton = async () => {
        enqueueSnackbar("Image Deleting...");
        await api("DELETE", `/project/${projectId}/image/${imageId}`);
        await mutate();
        enqueueSnackbar("Image Deleted", { variant: "success" });
        // navigate("/team");
    };



    return <Grid item xs={12} md={12} lg={12}>
        <div>
            <img src={image} style={{ width: "100%" }} alt={"imageOfProject"} />

            {/* <DeleteIcon /> */}
            {team.permissions.includes("editProject") &&
                areYouSure === false ? (
                <Button
                    variant="contained"
                    color="error"
                    onClick={deletePictureButton}
                >
                    <DeleteIcon />
                </Button>
            ) : null}

            {areYouSure ? (
                <>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={areYouSureButton}
                    >
                        Are you sure?
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setAreYouSure(false)}
                    >
                        No
                    </Button>
                </>
            ) : null}
        </div>
    </Grid>
}