import { Button, Card, Grid } from "@mui/material";
import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";

import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import { useDesigns } from "../../hooks/useDesigns";

import Delete from "@mui/icons-material/Delete";
import * as React from "react";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

export const DesignPicker = () => {
  const { projectId } = useParams();
  const { designs, isLoading, error } = useDesigns();
  const { project, mutate } = useProject(projectId);
  const { teamIsLoading, team } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    console.log(error);
    return <p>Something went wrong</p>;
  }

  if (designs.length === 0) {
    return (
      <Card>
        <div>
          <h1>Design Picker</h1>
          <p>No Designs</p>
          <p>
            Please{" "}
            {!team.permissions.includes("createDesign") ? "have an owner " : ""}
            create a design
          </p>
        </div>
      </Card>
    );
  }

  const removeDesignFromProject = async () => {
    enqueueSnackbar("Removing Design from Project");
    await api("PUT", `/project/${projectId}`, { designId: null });
    await mutate();
    enqueueSnackbar("Design Removed from Project", { variant: "success" });
  };
  const saveDesignToProject = async (design) => {
    console.log(design);

    // if (project.designId === design.id) return;
    design = designs.find((d) => d.name === design);
    console.log(design);
    enqueueSnackbar("Changing Project Design");

    await api("PUT", `/project/${project.id}`, { designId: design.id });
    mutate({ ...project, designId: design.id }, { revalidate: false });
    enqueueSnackbar("Changed Design", { variant: "success" });
  };

  return (
    <Card>
      <div>
        <h1>Design Picker</h1>

        <Grid container>
          <Stack sx={{ width: "100%" }}>
            <Autocomplete
              id="free-solo-demo"
              value={designs.find((d) => project.designId === d.id)?.name}
              options={designs
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((option) => option.name)}
              onChange={(e, newValue) => {
                console.log(newValue);
                saveDesignToProject(newValue);
              }}
              // clearOnBlur={removeDesignFromProject}
              // selectOnFocus={saveDesignToProject}
              renderInput={(params) => (
                <TextField {...params} label="Choose a Design" />
              )}
            />
          </Stack>

          {/* {designs
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((design) => {
              return (
                <Grid key={design.id} item xs={6} md={4} lg={2}>
                  <div>
                    <Design {...design} />
                  </div>
                </Grid>
              );
            })} */}
          {project.designId && team.permissions.includes("editProject") ? (
            <Grid item xs={12} md={12} lg={2}>
              <div>
                <Button variant="contained" onClick={removeDesignFromProject}>
                  <Delete />
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Card>
  );
};

const Design = (design) => {
  const { projectId } = useParams();

  const { project, mutate, isLoading } = useProject(projectId);
  const { teamIsLoading, team } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (
    project.designId !== design.id &&
    !team.permissions.includes("editProject")
  ) {
    return null;
  }
  const saveDesignToProject = async () => {
    if (project.designId === design.id) return;
    enqueueSnackbar("Changing Project Design");
    await api("PUT", `/project/${project.id}`, { designId: design.id });
    mutate({ ...project, designId: design.id }, { revalidate: false });
    enqueueSnackbar("Changed Design", { variant: "success" });
  };

  return (
    <Button
      fullWidth
      variant="contained"
      onClick={saveDesignToProject}
      color={project.designId === design.id ? "success" : "primary"}
    >
      {design.name}
    </Button>
  );
};
