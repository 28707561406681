import useSWR from "swr";

export function useTeam(team = true) {
  const { data, error, isLoading, mutate } = useSWR(team ? `/team` : null);

  return {
    team: data,
    isLoading,
    teamIsLoading: isLoading,
    error,
    mutate,
  };
}
