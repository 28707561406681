import useSWR from "swr";

export function useProjects() {
  const { data, error, isLoading, mutate } = useSWR(`/projects`);

  return {
    projects: data,
    isLoading,
    error,
    mutate,
  };
}
