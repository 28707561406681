import useSWR from "swr";

export function useDesign(id) {
  const { data, error, isLoading, mutate } = useSWR(`/design/${id}`);

  return {
    design: data,
    isLoading,
    error,
    mutate,
  };
}
