import useSWR from "swr";

export function useProject(id) {
  const { data, error, isLoading, mutate } = useSWR(`/project/${id}`);

  return {
    project: data,
    isLoading,
    error,
    mutate,
  };
}
