import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Button, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { useProject } from "./hooks/useProject";
import { useParams } from "@reach/router";
import { api } from "./util/api";
import { useTeam } from "./hooks/useTeam";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { enqueueSnackbar } from "notistack";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
const icon = new L.Icon({
  iconUrl: require("./close.png"),
  iconRetinaUrl: require("./close.png"),
  iconSize: [30, 30],
  //   shadowSize: [50, 64],
  //   iconAnchor: [22, 94],
  //   shadowAnchor: [4, 62],
  //   popupAnchor: [-3, -76],
});

function distance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Earth's radius in kilometers
  const latDistance = ((lat2 - lat1) * Math.PI) / 180; // Convert to radians
  const lonDistance = ((lon2 - lon1) * Math.PI) / 180; // Convert to radians
  const a =
    Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(lonDistance / 2) *
      Math.sin(lonDistance / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // convert kms to feet
  const distance = earthRadius * c * 3280.84; // Distance in feet
  return distance;
}

function DraggableMarker({ mutate, project }) {
  const [draggable, setDraggable] = useState(true);
  const markerRef = React.useRef(null);
  // const eventHandlers = React.useMemo(
  //   () => ({
  //     dragend() {
  //       const marker = markerRef.current;
  //       if (marker != null) {
  //         // mutate(
  //         //   { ...project, position: marker.getLatLng() },
  //         //   { revalidate: false }
  //         // );
  //         // api("PUT", `/project/${project.id}`, {
  //         //   position: marker.getLatLng(),
  //         // });
  //       }
  //     },
  //   }),
  //   [mutate]
  // );
  const toggleDraggable = React.useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  const setPost = () => {
    const marker = markerRef.current;

    const poly = [...(project.polylines || []), marker.getLatLng()];
    const math = distances(poly);
    let sharedPosts = math.length - 1;
    if (sharedPosts < 0) {
      sharedPosts = 0;
    }
    const newProjectDetails = {
      polylines: poly,
      lengths: math,
      mapCenter: marker.getLatLng(),
      sharedPosts,
      position: marker.getLatLng(),
    };
    mutate(
      {
        ...project,
        ...newProjectDetails,
      },
      { revalidate: false }
    );

    api("PUT", `/project/${project.id}`, {
      ...project,
      ...newProjectDetails,
    });
  };

  // const reset = () => {
  //   mutate({ ...project, polylines: [], lengths: [] }, { revalidate: false });
  //   api("PUT", `/project/${project.id}`, {
  //     polylines: [],
  //     lengths: [],
  //     sharedPosts: 0,
  //   });
  // };

  const wrongButton = () => {
    const newPolyLines = project.polylines.slice(
      0,
      project.polylines.length - 1
    );

    const newLengths = project.lengths.slice(0, project.lengths.length - 1);

    mutate(
      { ...project, polylines: newPolyLines, lengths: newLengths },
      { revalidate: false }
    );

    api("PUT", `/project/${project.id}`, {
      polylines: newPolyLines,
      lengths: newLengths,
    });
  };

  // console.log(distancesArray);
  return (
    <>
      {/* make button thiner with css */}
      {/* <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={reset}
      >
        <RestartAltIcon />
      </IconButton> */}
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={wrongButton}
      >
        <WrongLocationIcon />
      </IconButton>
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        onClick={setPost}
        variant="contained"
      >
        <AddLocationAltIcon />
      </IconButton>
      <Marker
        draggable={draggable}
        // eventHandlers={{
        //   dragend: () => {
        //     const marker = markerRef.current;
        //   },
        // }}
        position={project.position || [0, 0]}
        ref={markerRef}
        icon={icon}
      >
        <Popup minWidth={90}>
          <Button
            variant={draggable ? "contained" : null}
            onClick={toggleDraggable}
          >
            {draggable ? "draggable" : "locked"}
          </Button>
          {/* <Button></Button> */}
        </Popup>
        <Polyline
          pathOptions={{ color: "lime" }}
          positions={project.polylines || []}
        />
      </Marker>
    </>
  );
}

const LocateMeButton = ({
  setPosition = () => {},
  address,

  submitAddress = () => {},
  project,
  mutate,
}) => {
  const map = useMapEvents({
    locationfound(e) {
      mutate({ ...project, position: e.latlng }, { revalidate: false });

      api("PUT", `/project/${project.id}`, {
        position: e.latlng,
      });
      // setPosition(e.latlng);
      map.flyTo(e.latlng, 18);
    },
  });
  const click = () => {
    map.locate({ enableHighAccuracy: true });
  };
  const callback = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${project.address}, ${project.city}, ${project.state}, ${project.country}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!project.address) {
          enqueueSnackbar(
            "Project has no address, dont forget to save the project before you locate it",
            { variant: "info", autoHideDuration: 5000 }
          );
        }

        // if (!project.city) {
        //   enqueueSnackbar(
        //     "Project has no city, dont forget to save the project before you locate it",
        //     { variant: "info", autoHideDuration: 5000 }
        //   );
        // }
        if (data.length === 0) {
          enqueueSnackbar(
            "Address not found try to be more specific, input coordinates into the address field if nothing else works",
            { variant: "info", autoHideDuration: 5000 }
          );
        } else {
          console.log(data);
          const coords = {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          };
          map.flyTo(
            {
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            },
            18
          );
          mutate({ ...project, position: coords }, { revalidate: false });

          api("PUT", `/project/${project.id}`, {
            position: coords,
          });
        }

        // mutate(
        //   api("PUT", `/project/${project.id}`, {
        //     position: { lat: data.results[0].geometry.location.lat, lng: data.results[0].geometry.location.lon },
        //   }),
        //   {
        //     optimisticData: {
        //       ...project,
        //       position: { lat: data.results[0].geometry.location.lat, lng: data.results[0].geometry.location.lon },
        //     },
        //   }
        // );
      });
  };

  return (
    <>
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={callback}
      >
        <SearchIcon />
      </IconButton>

      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={click}
      >
        <GpsFixedIcon />
      </IconButton>
    </>
  );
};

// const arc =
//   "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

const distances = (polylines) => {
  try {
    if (polylines?.length > 0) {
      return polylines.reduce((acc, curr, index) => {
        if (index === 0) {
          return acc;
        }

        const dis = distance(
          curr.lat,
          curr.lng,
          polylines[index - 1].lat,
          polylines[index - 1].lng
        );

        return [...acc, dis.toFixed(0)];
      }, []);
    }
  } catch (e) {
    return [];
  }
};

const MapWithMarkers = () => {
  const { projectId } = useParams();
  const {
    project = {
      // position: { lat: 44.38, lng: -79.69 },
      // address: "32 Hun Cres, Etobicoke, Ontario",
      // polylines: [],
    },
    isLoading,
    error,
    mutate,
  } = useProject(projectId);
  const { isLoading: teamIsLoading, error: teamError, team } = useTeam();

  // const [address, setAddress] = useState("32 Hun Cres, Etobicoke, Ontario");

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  // if ((project && !project.polyines) || !project.position) {
  //   project.position = { lat: 44.38, lng: -79.69 };
  //   project.polylines = [];
  // }

  // let distancesArray = distances(project.polylines);
  // console.log(project.position);
  // console.log(project.polylines);

  return (
    <>
      {/* <div>
        {distancesArray?.map((polyline, idx) => (
          <TextField key={`length-${idx}`} value={polyline} />
        ))}
      </div> */}

      <MapContainer
        center={project.mapCenter || [0, 0]}
        dragging={true}
        // touchZoom={true}
        doubleClickZoom={false}
        zoom={project.mapCenter ? 20 : 15}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{ height: "350px", zoom: "1.65", textAlign: "center" }}
      >
        <TileLayer
          url={`https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
          maxZoom={20}
          subdomains={["mt1", "mt2", "mt3"]}
        />{" "}
        <LocateMeButton
          address={project.address}
          project={project}
          mutate={mutate}
        />
        {team.permissions.includes("editProject") ? (
          <DraggableMarker
            // position={project.position}
            // setPosition={setPosition}
            // polylines={project.polylines}
            mutate={mutate}
            project={project}
            // setPolylines={setPolylines}
          />
        ) : null}
        <ZoomControl position="bottomleft" />
      </MapContainer>
    </>
  );
};

export default MapWithMarkers;
