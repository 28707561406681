import { Card, Grid, MenuItem, Button, Select, TextField } from "@mui/material";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  Select as SelectFormik,
  TextField as TextFieldFormik,
} from "formik-mui";
import { v4 } from "uuid";
import { useTeam } from "../../hooks/useTeam";
import { enqueueSnackbar } from "notistack";
import DesignConfig from "./Config";
import { useDesigns } from "../../hooks/useDesigns";
// import { useAuthenticator } from "@aws-amplify/ui-react";
import SaveIcon from "@mui/icons-material/Save";
import { api } from "../../util/api";
import DeleteIcon from "@mui/icons-material/Delete";

const decimalCleanUp = (target) => {
  target = target.replace(/[^0-9.]/g, "");

  // Ensure that there's only one decimal point
  const decimalCount = (target.match(/\./g) || []).length;
  if (decimalCount > 1) {
    target = target.slice(0, target.lastIndexOf("."));
  }
  return target;
};

const MaterialForm = ({ designPageState, dispatch, team, ...material }) => {
  // const [stateMaterial, setMaterial] = useState(material);

  const gridSplit = team.tierLimits.seePrices ? 4 : 6;
  return (
    <Card>
      <div>
        <Grid container>
          <Grid item xs={12} md={gridSplit}>
            <TextField
              style={{ width: "100%" }}
              label="Material:"
              value={material.material}
              onChange={(e) => {
                let mat = { ...material, material: e.target.value };

                // setMaterial(mat);
                dispatch({ type: "updateMaterial", payload: mat });
              }}
            />
          </Grid>
          {/* <Grid item xs={6} md={4} lg={2}>
            <TextField
              style={{ width: "100%" }}
              label="Weight (lbs):"
              value={stateMaterial.weight}
              onChange={(e) => {
                let mat = {
                  ...stateMaterial,
                  weight: decimalCleanUp(e.target.value),
                };
                setMaterial(mat);
                dispatch({ type: "updateMaterial", payload: mat });
              }}
            />
          </Grid> */}
          {team?.tierLimits.seePrices ? (
            <Grid item xs={12} md={gridSplit}>
              <TextField
                style={{ width: "100%" }}
                label="Price ($):"
                value={material.price}
                onChange={(e) => {
                  let mat = {
                    ...material,
                    price: decimalCleanUp(e.target.value),
                  };
                  // setMaterial(mat);
                  dispatch({ type: "updateMaterial", payload: mat });
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={gridSplit}>
            <TextField
              label="Quantity:"
              style={{ width: "100%" }}
              value={material.quantity}
              onChange={(e) => {
                let mat = {
                  ...material,
                  quantity: decimalCleanUp(e.target.value),
                };
                // setMaterial(mat);
                dispatch({ type: "updateMaterial", payload: mat });
                // if (e.target.value === "0") {
                // }
              }}
            />

            <Button>

              <DeleteIcon onClick={() => dispatch({ type: "removeMaterial", payload: material })} />
            </Button>

          </Grid>
          {material.category === "other" ? (
            <Grid item xs={12} md={12} lg={12}>
              <Select
                fullWidth
                value={material.fenceCategory}
                onChange={(e) => {
                  let mat = { ...material, fenceCategory: e.target.value };
                  // setMaterial(mat);
                  dispatch({ type: "updateMaterial", payload: mat });
                }}
                label="Fence Category"
              >
                <MenuItem value={"panel"}>Panel</MenuItem>
                <MenuItem value={"post"}>Post</MenuItem>
              </Select>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Card>
  );
};

const postCategories = ["post", "concrete"];
const panelCategories = ["rail", "board", "other"];

const AddMaterialForm = ({ dispatch }) => {
  const { team, isLoading, error } = useTeam();
  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveButton = (values, actions) => {
    console.log(values);
    actions.setSubmitting(false);
    // return;
    actions.resetForm();

    if (values.category !== "other") {
      if (postCategories.includes(values.category)) {
        values.fenceCategory = "post";
      }
      if (panelCategories.includes(values.category)) {
        values.fenceCategory = "panel";
      }
    }

    dispatch({ type: "addMaterial", payload: { ...values, id: v4() } });
    enqueueSnackbar("Material Added", { variant: "success" });
  };
  // id: v4(),
  // material: "PT 4x4x10",
  // category: "post",
  // price: 22.03,
  // quantity: 1,
  // fenceCategory: "post",
  // weight: 38.5,

  return (
    <Card>
      <div>
        <h3>Create a Material</h3>
        <Formik
          initialValues={{
            material: "",
            category: "post",
            price: "",
            quantity: "",
            fenceCategory: "post",
            // weight: "",
          }}
          validationSchema={Yup.object().shape({
            material: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            category: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            price: Yup.number("Must be Number"),
            quantity: Yup.number("Must be Number").required("Required"),
            // weight: Yup.number("Must be Number").required("Required"),
            fenceCategory: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={saveButton}
        >
          {({ submitForm, isSubmitting, isValid, errors, values }) => (
            <Form>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Field
                    component={SelectFormik}
                    name="category"
                    label="Material Category"
                  >
                    <MenuItem value={"post"}>Post</MenuItem>
                    <MenuItem value={"concrete"}>Concrete</MenuItem>
                    <MenuItem value={"board"}>Boards</MenuItem>
                    <MenuItem value={"rail"}>Rail</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Field>
                </Grid>
                {values.category === "other" ? (
                  <Grid item xs={12} md={12}>
                    <Field
                      component={SelectFormik}
                      name="fenceCategory"
                      label="Fence Category"
                    >
                      <MenuItem value={"post"}>Post</MenuItem>
                      <MenuItem value={"panel"}>Panel</MenuItem>
                    </Field>
                  </Grid>
                ) : null}

                <Grid item xs={12} md={4}>
                  <Field
                    component={TextFieldFormik}
                    name="material"
                    label="Name"
                  // isDisabled={team.team.tier === "contractor"}
                  />
                </Grid>

                {/* <Grid item xs={12} md={4}>
                  <Field
                    component={TextFieldFormik}
                    name="weight"
                    label="Weight (lbs)"
                    // isDisabled={team.team.tier === "contractor"}
                  />
                </Grid> */}
                {team.team.tier === "small" ||
                  team.team.tier === "enterprise" ? (
                  <Grid item xs={12} md={4}>
                    <Field
                      component={TextFieldFormik}
                      name="price"
                      label="Price ($)"
                    //   isDisabled={team.team.tier === "contractor"}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} md={4}>
                  <Field
                    component={TextFieldFormik}
                    name="quantity"
                    label="Quantity"
                  // isDisabled={team.team.tier === "contractor"}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {isSubmitting ? (
                    <div className="spinner" />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      onClick={submitForm}
                    >
                      Save Material
                    </Button>
                  )}
                </div>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export const MaterialList = ({ designPageState, dispatch }) => {
  const { designs, isLoading, error, mutate } = useDesigns();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveButton = async () => {
    if (designs.map((d) => d.name).includes(designPageState.name)) {
      enqueueSnackbar("Design name already exists", { variant: "error" });
      return;
    }
    if (designPageState.name === "") {
      enqueueSnackbar("Please name your design", { variant: "error" });
      return;
    }

    if (designPageState.lengthOfSection === "") {
      enqueueSnackbar("Please set a panel length", { variant: "error" });
      return;
    }

    if (designPageState.materials.length === 0) {
      enqueueSnackbar("Design has no materials", { variant: "error" });
      return;
    }
    // console.log(designPageState);
    enqueueSnackbar("Saving Design");

    await api("POST", `/team/design`, designPageState);
    await mutate();

    dispatch({ type: "resetDesign" });

    enqueueSnackbar("Design Saved", { variant: "success" });
  };

  designPageState.materials = designPageState.materials.sort(
    (a, b) => a.material - b.material
  );

  const rails = designPageState.materials.filter(
    (material) => material.category === "rail"
  );
  const post = designPageState.materials.filter(
    (material) => material.category === "post"
  );
  const concrete = designPageState.materials.filter(
    (material) => material.category === "concrete"
  );
  const boards = designPageState.materials.filter(
    (material) => material.category === "board"
  );
  const other = designPageState.materials.filter(
    (material) => material.category === "other"
  );

  return (
    <Card>
      <div>
        <DesignConfig designPageState={designPageState} dispatch={dispatch} />
        <div
          style={{
            textAlign: "center",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Material List</h2>

          {team.tierLimits.designs <= designs.length ? (
            <p>No More Designs Allowed</p>
          ) : (
            <Button variant="contained" onClick={saveButton}>
              <SaveIcon />
            </Button>
          )}
        </div>

        <h3>Rails</h3>
        {rails.map((material, idx) => {
          return (
            <MaterialForm
              designPageState={designPageState}
              dispatch={dispatch}
              team={team}
              {...material}
              key={`material-rail-${idx}`}
            />
          );
        })}
        <h3>Posts</h3>
        {post.map((material, idx) => {
          return (
            <MaterialForm
              designPageState={designPageState}
              dispatch={dispatch}
              team={team}
              {...material}
              key={`material-post-${idx}`}
            />
          );
        })}
        <h3>Concrete</h3>
        {concrete.map((material, idx) => {
          return (
            <MaterialForm
              designPageState={designPageState}
              dispatch={dispatch}
              team={team}
              {...material}
              key={`material-concrete-${idx}`}
            />
          );
        })}
        <h3>Boards</h3>
        {boards.map((material, idx) => {
          return (
            <MaterialForm
              designPageState={designPageState}
              dispatch={dispatch}
              team={team}
              {...material}
              key={`material-boards-${idx}`}
            />
          );
        })}
        <h3>Other</h3>
        {other.map((material, idx) => {
          return (
            <MaterialForm
              designPageState={designPageState}
              dispatch={dispatch}
              team={team}
              {...material}
              key={`material-other-${idx}`}
            />
          );
        })}

        <AddMaterialForm dispatch={dispatch} />
      </div>
    </Card>
  );
};

export default MaterialList;
