import { Card, Grid } from "@mui/material";
import shadowbox from "./shadowbox2.png";
import flat from "./flat.png";
import { v4 } from "uuid";

import { enqueueSnackbar } from "notistack";

export const shadowBox = {
  image: shadowbox,
  name: "Shadow Box",
  lengthOfSection: "8",
  materials: [
    {
      id: v4(),
      material: "PT 4x4x10",
      category: "post",
      price: 22.03,
      quantity: 1,
      fenceCategory: "post",
      weight: 38.5,
    },
    {
      id: v4(),

      material: "PT 2x4x8",
      category: "rail",
      price: 9.23,
      quantity: 3,
      fenceCategory: "panel",
      weight: 11.39,
    },
    {
      id: v4(),

      material: "PT 1x6x6",
      category: "board",
      price: 5.59,
      quantity: 22,
      fenceCategory: "panel",
      weight: 6.78,
    },
    {
      id: v4(),

      material: "Concrete",
      category: "concrete",
      price: 6,
      quantity: 2,
      fenceCategory: "post",
      weight: 66,
    },
    {
      id: v4(),

      material: "2x4 Brackets",
      category: "other",
      price: 0.5,
      quantity: 6,
      fenceCategory: "panel",
      weight: 0.1,
    },
    {
      id: v4(),
      material: "Post Cap",
      category: "other",
      price: 5,
      quantity: 1,
      fenceCategory: "post",
      weight: 0.1,
    },
  ],
};

const privateFence = {
  image: flat,
  name: "Private",
  lengthOfSection: "8",
  materials: [
    {
      id: v4(),
      material: "PT 4x4x10",
      category: "post",
      price: 22.03,
      quantity: 1,
      fenceCategory: "post",
      weight: 38.5,
    },
    {
      id: v4(),

      material: "PT 2x4x8",
      category: "rail",
      price: 9.23,
      quantity: 3,
      fenceCategory: "panel",
      weight: 11.39,
    },
    {
      id: v4(),

      material: "PT 1x6x6",
      category: "board",
      price: 5.59,
      quantity: 17,
      fenceCategory: "panel",
      weight: 6.78,
    },
    {
      id: v4(),

      material: "Concrete",
      category: "concrete",
      price: 6,
      quantity: 2,
      fenceCategory: "post",
      weight: 66,
    },
    {
      id: v4(),

      material: "2x4 Brackets",
      category: "other",
      price: 0.5,
      quantity: 4,
      fenceCategory: "panel",
      weight: 0.1,
    },
    {
      id: v4(),

      material: "Post Cap",
      category: "other",
      price: 5,
      quantity: 1,
      fenceCategory: "post",
      weight: 0.1,
    },
  ],
};

const Picker = ({ dispatch, designPageState }) => {
  return (
    <Card>
      <div>
        <h2>Choose a Default Design</h2>

        <div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div
                onClick={() => {
                  dispatch({ type: "resetDesign" });
                  dispatch({ type: "copyDesign", payload: shadowBox });
                  enqueueSnackbar("Design Copied", { variant: "success" });
                }}
              >
                <h4>{shadowBox.name}</h4>
                <img
                  style={{ width: "100%" }}
                  src={shadowBox.image}
                  alt={shadowBox.name}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                onClick={() => {
                  dispatch({ type: "resetDesign" });
                  dispatch({ type: "copyDesign", payload: privateFence });
                  enqueueSnackbar("Design Copied", { variant: "success" });
                }}
              >
                <h4>{privateFence.name}</h4>

                <img
                  style={{ width: "100%" }}
                  src={privateFence.image}
                  alt={privateFence.name}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Card>
  );
};

export default Picker;
