import { Router } from "@reach/router";
import MyApp from "./App";
import Chain from "./Chain";
import ButtonAppBar from "./comp/NavBar";
import { useTeam } from "./hooks/useTeam";
import Design from "./pages/Design/Design";
import Project from "./pages/project/Project";
import Projects from "./pages/project/Projects";
import Team from "./pages/Team";
import AddUser from "./pages/Team/AddUser";

export const MainRouter = () => {
  const { isLoading, error } = useTeam();
  // const {
  //   subscription,
  //   isLoading: subLoading,
  //   error: subError,
  // } = useSubscription();

  // if (subscription) {
  //   window.location.href = subscription.url;
  // }

  if (isLoading) {
    return <div className="spinner" />;
  }

  // console.log(error?.response?.data);
  if (error && error?.response?.data.error === "Team has no subscription") {
    window.location.href = error?.response?.data.session.url;
  }

  if (error && error?.response?.data.error.includes("Cannot downgrade")) {
    // alert(error?.response?.data.error);
    // return <SubscriptionPage />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <>
      <ButtonAppBar />
      <Router>
        {/* <Dashboard path="/" /> */}
        <Team path="/team" />
        <Design path="/design" />
        <Projects path="/" />
        <Project path="/project/:projectId" />

        <AddUser path="/team/add-user" />
        <MyApp path="/wood" />
        <Chain path="/chainlink" />
        {/* <SubscriptionPage path="/subscription" /> */}
      </Router>
    </>
  );
};

// function Subscription({ team }) {
//   // Paste the stripe-pricing-table snippet in your React component
//   return (
//     <stripe-pricing-table
//       pricing-table-id="prctbl_1MpIg2BGd4QQHkBQ9vA0ukZ5"
//       publishable-key="pk_test_51Mp8ykBGd4QQHkBQvOLf430ZkcZEO2DJCaMT3IpxpJcAg6gajYZRKu0ok4Stckiz1Zq08U3QVU18gFQSEBG5EYJj002fU2Um0X"
//       client-reference-id={team}
//     ></stripe-pricing-table>
//   );
// }
