import { Button, Grid } from "@mui/material";
import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import camelToTitle from "../../util/case";
import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import Save from "@mui/icons-material/Save";
import { stringNotRequired } from "../../util/form";

export const ClientFormAddress = () => {
  const { projectId } = useParams();
  const { project, isLoading, error, mutate } = useProject(projectId);

  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveClientButton = async (values) => {
    enqueueSnackbar("Saving Client Details");
    await api("PUT", `/project/${project.id}`, values);
    await mutate();
    enqueueSnackbar("Client Details Updated", { variant: "success" });
  };

  const {
    clientFirstName,
    clientLastName,
    clientEmail,
    clientPhone,
    address,
    city,
    state,
    country,
  } = project;

  const customerKeys = [
    "clientFirstName",
    "clientLastName",
    "clientEmail",
    "clientPhone",
    "address",
    "city",
    "state",
    "country",
  ];

  return (
    <Formik
      initialValues={{
        clientFirstName,
        clientLastName,
        clientEmail,
        clientPhone,
        address,
        city,
        state,
        country,
      }}
      validationSchema={Yup.object().shape({
        clientFirstName: stringNotRequired,
        clientLastName: stringNotRequired,
        clientEmail: Yup.string().email("Invalid email").notRequired(),
        clientPhone: stringNotRequired,
        address: stringNotRequired,
        city: stringNotRequired,
        state: stringNotRequired,
        country: stringNotRequired,
      })}
      onSubmit={saveClientButton}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container>
            {customerKeys.map((projectKey) => {
              return (
                <Grid item xs={6} md={3} lg={2} key={projectKey}>
                  <Field
                    component={TextField}
                    name={projectKey}
                    label={camelToTitle(projectKey)}
                  />
                </Grid>
              );
            })}
            <Grid item xs={6} md={12} lg={12}>
              <div>
                {isSubmitting ? (
                  <div className="spinner" />
                ) : team.permissions.includes("editProject") ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    <Save />
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
