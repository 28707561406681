import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button, Card, Grid } from "@mui/material";
import { navigate, useNavigate } from "@reach/router";
import { Field, Form, Formik } from "formik";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useTeam } from "../hooks/useTeam";
import { api } from "../util/api";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import { useState } from "react";
import { useSubscription } from "../hooks/useSubscription";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
// import { mutate } from "swr";

const Team = () => {
  const { team, isLoading, error } = useTeam();
  const navigate = useNavigate();

  const {
    subscription,
    isLoading: subLoading,
    error: subError,
  } = useSubscription(team.permissions.includes("getSubscription"));

  if (isLoading || subLoading) {
    return <div className="spinner" />;
  }

  if (error || subError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const addUserButton = () => {
    navigate("/team/add-user");
  };

  const createUserButton = () => {
    if (!team.permissions.includes("addUserToTeam")) {
      return <p>Only Owners can Add Users</p>;
    }

    if (team.users.length >= team.tierLimits.users) {
      return <p>Max Users</p>;
    }

    return (
      <Button variant="contained" onClick={addUserButton}>
        Add User
      </Button>
    );
  };

  const teamConf = () => {
    if (team.team.tier === "contractor") {
      return <p>Subscribe to set Prices</p>;
    }
    if (team.permissions.includes("editTeam")) {
      return (
        <>
          <TeamConfig />
        </>
      );
    }

    return <></>;
  };

  return (
    <Card>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>{team.team.name}</h1>
          {createUserButton()}
        </div>
        <h2 style={{ textTransform: "uppercase" }}>{team.team.tier}</h2>
        {subscription ? (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = subscription.url;
            }}
          >
            Edit Subscription
          </Button>
        ) : null}
        {teamConf()}
        {team.users.map((user) => (
          <User key={user.id} user={user} team={team.team} />
        ))}
      </div>
    </Card>
  );
};

const User = ({ user, team }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [areYouSure, setAreYouSure] = useState(false);
  const { user: localUser } = useAuthenticator((context) => [context.user]);
  const { mutate } = useTeam();

  const deleteUserButton = async () => setAreYouSure(true);

  const areYouSureButton = async () => {
    enqueueSnackbar("User Deleting...");
    await api("DELETE", `/team/user/${user.id}`);
    await mutate();
    enqueueSnackbar("User Deleted", { variant: "success" });
    navigate("/team");
  };
  // const editUserButton = () => {

  // }

  return (
    <Card>
      <div>
        <Grid container>
          <Grid item xs={12} md={12}>
            <h2>
              {user.firstName} {user.lastName}
            </h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <h4>
              {localUser.username === user.id ? <u>{user.id}</u> : user.id}
            </h4>
          </Grid>
          <Grid item xs={12} md={6}>
            <h4 style={{ textTransform: "uppercase" }}>
              {team.users[user.id]}
            </h4>
          </Grid>
          <Grid item xs={12} md={4}>
            {localUser.username !== user.id &&
              team.users[localUser.username] === "owner" &&
              areYouSure === false ? (
              <Button
                variant="contained"
                color="error"
                onClick={deleteUserButton}
              >
                <DeleteIcon />
              </Button>
            ) : null}
            {areYouSure ? (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={areYouSureButton}
                >
                  Are you sure?
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAreYouSure(false)}
                >
                  No
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const TeamConfig = () => {
  const { team, isLoading, error, mutate } = useTeam();

  if (isLoading || !team) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveTeamButton = async (values) => {
    enqueueSnackbar("Saving Team Details");
    console.log(values);
    console.log(`/team/${team.team.id}`);
    await api("PUT", `/team/${team.team.id}`, values);
    mutate({ ...team, ...values });
    enqueueSnackbar("Client Details Updated", { variant: "success" });
  };

  const { stateTax, labourCostPerFt, materialMultiplier, removalCostPerFt } =
    team.team;

  const customerKeys = [
    "stateTax",
    "labourCostPerFt",
    "materialMultiplier",
    "removalCostPerFt",
  ];
  const customerKeysLabel = [
    "State Tax (%)",
    "Labor Cost per Ft ($)",
    "Material Markup (%)",
    "Remove Cost per Ft ($)",
  ];

  return (
    <Card>
      <div>
        <h2>Team Config</h2>
        <LogoUpload team={team} mutate={mutate} />
        <Formik
          initialValues={{
            stateTax,
            labourCostPerFt,
            materialMultiplier,
            removalCostPerFt,
          }}
          validationSchema={Yup.object().shape({
            stateTax: Yup.number().notRequired("Required"),
            labourCostPerFt: Yup.number().notRequired("Required"),
            removalCostPerFt: Yup.number().notRequired("Required"),
          })}
          onSubmit={saveTeamButton}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container>
                {customerKeys.map((projectKey, idx) => {
                  return (
                    <Grid item xs={6} md={4} key={projectKey}>
                      <Field
                        component={TextField}
                        name={projectKey}
                        label={customerKeysLabel[idx]}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={6} md={12} lg={12}>
                  <div>
                    {isSubmitting ? (
                      <div className="spinner" />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SaveIcon />
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

const LogoUpload = ({ team, mutate }) => {


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Create a new FileReader instance
    // const reader = new FileReader();

    // Set the onload event handler for the reader
    // reader.onload = async () => {
    //   const newTeam = await api("PUT", `/team/${team.team.id}`, {
    //     logo: reader.result,
    //   });
    //   mutate({ ...team, team: newTeam }, { revalidate: false });
    //   // setImageBase64(reader.result);
    // };

    // Read the file as a base64-encoded string
    // reader.readAsDataURL(file);
    const reader = new FileReader();

    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        // setWidth(targetWidth);
        // setHeight(targetHeight);

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        })
          .then(async (resizedBitmap) => {
            let quality = 0.99;
            let canvas = document.createElement("canvas");
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
            let dataUrl = canvas.toDataURL("image/jpeg", quality);
            console.log(dataUrl.length);
            while (dataUrl.length > 1000000) {
              console.log(dataUrl.length);
              quality -= 0.01;
              dataUrl = canvas.toDataURL("image/jpeg", quality);
            }

            const newTeam = await api("PUT", `/team/${team.team.id}`, {
              logo: dataUrl,
            });
            mutate({ ...team, team: newTeam }, { revalidate: false });
          })
          .catch((e) => {
            console.log(e);
          });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        {team.team.logo && (
          <div>
            <img
              src={team.team.logo}
              alt={team.team.name}
              style={{ maxWidth: "35vw" }}
            />
          </div>
        )}
        <div>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button component="span" variant="contained">
              Upload Logo
            </Button>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Team;
