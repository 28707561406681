import { Formik, Form, Field } from "formik";
import { TextField, RadioGroup } from "formik-mui";

import { Button, Grid, Card, FormControlLabel, Radio } from "@mui/material";
import * as Yup from "yup";
import { navigate } from "@reach/router";
import { useTeam } from "../../hooks/useTeam";
import { api } from "../../util/api";
import { useSnackbar } from "notistack";

const AddUser = () => {
  const { mutate, team, isLoading, error } = useTeam();
  const { enqueueSnackbar } = useSnackbar();

  const addUser = async (values, actions) => {
    try {
      // const newTeam =
      enqueueSnackbar("User Creating..");

      await api("POST", "/team/user", values);
      enqueueSnackbar("User Added, Have them create an account", {
        variant: "success",
      });
      navigate("/team");
      mutate();
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });

      // actions.setFieldError("email", error.response.data);
      // console.log();
      // console.log(Object.keys(error));
    }

    // console.log(newTeam);

    // if (newTeam.status === 200) {

    // } else {
    //
    // }
  };

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <Card>
      <div>
        <h1>Add User</h1>
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            role: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            firstName: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            lastName: Yup.string()
              .min(1, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            role: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={addUser}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    // type="text"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="firstName"
                    // type="text"
                    label="First Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="lastName"
                    // type="text"
                    label="Last Name"
                  />{" "}
                </Grid>

                <Grid item xs={12}>
                  <Field component={RadioGroup} name="role">
                    {team.tierLimits.roles.map((role) => (
                      <FormControlLabel
                        key={role}
                        value={role}
                        control={<Radio disabled={isSubmitting} />}
                        label={role.toUpperCase()}
                      />
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  {isSubmitting ? (
                    <div className="spinner" />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Create
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};
export default AddUser;
