import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Authenticator } from "@aws-amplify/ui-react";
// import { Loading } from "../../App";
import { useNavigate } from "@reach/router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useTeam } from "../hooks/useTeam";
import GroupIcon from "@mui/icons-material/Group";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeIcon from "@mui/icons-material/Home";
import { useSettings } from "../hooks/useSettings";

function ButtonAppBar() {
  // const { user, setUser } = React.useContext(UserContext);
  const { settings } = useSettings();

  const navigate = useNavigate();
  // const location = useLocation();
  // const [open, setOpen] = React.useState(false);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const {
    team,
    // isLoading,
    //  error
  } = useTeam(settings?.sk === "no-team" ? false : true);

  React.useEffect(() => {
    Hub.listen("auth", (data) => {
      // console.log(event);
      switch (data.payload.event) {
        case "signOut":
          window.location.reload();
          break;
        default:
          break;
      }
    });
  }, []);
  // if (isLoading) {
  //   return <div className="spinner" />;
  // }

  // if (isPending) {
  //   return <div className="spinner" />;
  // }

  const signOutButton = () => {
    signOut();
    navigate("/");
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* <span onClick={() => navigate("/")} style={{ width: "100%" }}>
                <img src={logo} style={{ color: "white", height: "5em" }} />
              </span> */}

          {user?.attributes?.email && team?.permissions ? (
            <span onClick={() => navigate("/")} style={{ width: "100%" }}>
              <HomeIcon />
            </span>
          ) : (
            <span onClick={() => navigate("/")} style={{ width: "100%" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                FenceWiz.com
              </Typography>
            </span>
          )}
          {user?.attributes?.email && team?.permissions && (
            <span onClick={() => navigate("/team")} style={{ width: "100%" }}>
              <GroupIcon />
            </span>
          )}
          {team?.permissions.includes("editDesign") && (
            <span onClick={() => navigate("/design")} style={{ width: "100%" }}>
              <ConstructionIcon />
            </span>
          )}

          <Button color="inherit" onClick={signOutButton}>
            <p style={{}}>{user?.attributes?.email && "Logout"}</p>
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}

export const LoginModal = ({ open, setOpen }) => {
  // const [open, setOpen] = React.useState(true);

  return (
    <Modal
      open={open}
      onBackdropClick={() => setOpen(false)}
      onClose={() => setOpen(false)}
    >
      <Box>
        <Authenticator>
          {({ user, signOut }) => {
            // console.log(user);

            return (
              <main>
                <h4>Hey, {user.attributes.email}!</h4>
                <Button variant="outlined" color="warning" onClick={signOut}>
                  Sign out
                </Button>
              </main>
            );
          }}
        </Authenticator>
      </Box>
    </Modal>
  );
};

export default ButtonAppBar;
