import useSWR from "swr";

export function useSubscription(gotPerms = false) {
  const { data, error, isLoading, mutate } = useSWR(
    gotPerms ? `/subscription` : null
  );

  return {
    subscription: data,
    isLoading,
    error,
    mutate,
  };
}
