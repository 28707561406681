import { Button, Card } from "@mui/material";
import { navigate, useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";

import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { Materials } from "./ProjectMaterials";
import { ClientFormAddress } from "./ClientFormAddress";
import { Lengths } from "./Lengths";
import { DesignPicker } from "./DesignPicker";
import { ProjectNumbers } from "./ProjectNumbers";
import { Notes } from "./Notes";

const Project = () => {
  const { projectId } = useParams();
  const { project, isLoading, error, mutate } = useProject(projectId);
  const [areYouSure, setAreYouSure] = useState(false);

  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const deleteButton = async () => {
    enqueueSnackbar("Deleting Project...");
    await api("DELETE", `/project/${project.id}`);
    enqueueSnackbar("Deleted", { variant: "success" });
    navigate(`/`);
  };

  return (
    <Card>
      <div>
        <h1>Project</h1>
        <ClientFormAddress />
        <Notes />

        <Lengths project={project} mutate={mutate} />
        <DesignPicker />

        {project.designId ? (
          <>
            <Materials />
            <ProjectNumbers />
          </>
        ) : null}

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {team.permissions.includes("deleteProject") ? (
          !areYouSure ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => setAreYouSure(true)}
            >
              Delete
            </Button>
          ) : (
            <>
              <Button variant="contained" color="error" onClick={deleteButton}>
                Are you sure?
              </Button>
              <Button variant="contained" onClick={() => setAreYouSure(false)}>
                No
              </Button>
            </>
          )
        ) : null}
      </div>
    </Card>
  );
};

export default Project;
